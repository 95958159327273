import useTableData from '@/hooks/useTableData'
import { webApiService } from '@/services'
import { Button, Input, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { MdCloudDownload, MdOutlineSearch } from 'react-icons/md'
import UploadProof from './components/UploadProof'

const columns = [
  { name: '#', uid: 'id_tradein' },
  { name: 'Email', uid: 'email' },
  { name: 'Nombre', uid: 'name_owner' },
  { name: 'DNI', uid: 'rut' },
  { name: 'Banco', uid: 'bank' },
  { name: 'Numero Cuenta', uid: 'account' },
  { name: 'Numero Cuenta interbancaria', uid: 'interbank_account' },
  { name: 'Comprobante', uid: 'url_proof' },
  { name: '', uid: 'actions' }
]

const excelName = 'Listado_DatosBancarios'

const DataBankPage = () => {
  const [data, setData] = useState<{ loading: boolean, list: any[] }>({ loading: false, list: [] })
  const {
    sortDescriptor,
    filterValue,
    sortedItems,
    bottomContent,
    onSearchChange,
    setSortDescriptor,
    setPage,
    onClear,
    exportToCSV
  } = useTableData(columns, excelName, data.list)

  const loadData = async () => {
    setData({ ...data, loading: true })
    await webApiService.getAllDataBank().then((response) => {
      setData({ loading: false, list: response.data })
      setPage(1)
    })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCell: any = (item: any, columnKey: any) => {
    const cellValue = item[columnKey]

    /* eslint default-case: "error" */
    switch (columnKey) {
      case 'url_proof':
        return cellValue === '' ? cellValue : (<Button as={Link} size='sm' color='warning' href={cellValue} target='_blank'>Ver</Button>)
      case 'actions':
        return (<UploadProof idTradein={item.id_tradein} />)
      default:
        return (<span className='text-xs'>{cellValue}</span>)
    }
  }

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='space-y-4'
    >
      <div className='bg-white rounded-2xl shadow-md p-2 mt-4'>
        <div className='grid grid-cols-2'>
          <div className='my-4'>
            <Input
              label='Search'
              isClearable
              radius='lg'
              value={filterValue}
              onValueChange={onSearchChange}
              onClear={() => onClear()}
              classNames={{
                label: 'text-black/50 dark:text-white/90',
                input: [
                  'bg-transparent',
                  'text-black/90 dark:text-white/90',
                  'placeholder:text-default-700/50 dark:placeholder:text-white/60'
                ],
                innerWrapper: 'bg-transparent',
                inputWrapper: [
                  'bg-default-200/50',
                  'dark:bg-default/60',
                  'backdrop-blur-xl',
                  'backdrop-saturate-200',
                  'hover:bg-default-200/70',
                  'dark:hover:bg-default/70',
                  'group-data-[focus=true]:bg-default-200/50',
                  'dark:group-data-[focus=true]:bg-default/60',
                  '!cursor-text'
                ]
              }}
              placeholder='Buscar...'
              startContent={
                <MdOutlineSearch className='text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0' />
          }
            />
          </div>
          <div className='flex items-center justify-end'>
            <Button onClick={exportToCSV} color='success' className='text-white' variant='shadow' endContent={<MdCloudDownload />}>Descargar</Button>
          </div>
        </div>
        <Table
          aria-label='Example table with client side sorting'
          sortDescriptor={sortDescriptor}
          onSortChange={setSortDescriptor}
          bottomContent={bottomContent}
          bottomContentPlacement='outside'
        >
          <TableHeader columns={columns.filter(e => e.uid !== 'serie' && e.uid !== 'cancel_reason')}>
            {(column) => (
              <TableColumn key={column.uid} align={column.uid === 'actions' ? 'center' : 'start'} allowsSorting={column.uid !== 'actions'} className={column.uid === 'nombre' || column.uid === 'actions' || column.uid === 'ordenCompraCodigo' ? '' : 'md:table-cell'}>
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            isLoading={data.loading}
            loadingContent={<Spinner label='Loading...' />}
          >
            {
              sortedItems.map((item: any) => (
                <TableRow key={item.id}>
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    </motion.div>
  )
}

export default DataBankPage
