import { PrivateRoutes } from '@/constants/routes'
import useTableData from '@/hooks/useTableData'
import { webApiService } from '@/services'
import { currencyFormat } from '@/utils/currencyFormat'
import { StatusColor } from '@/utils/StatusColor.utility'
import { Button, Chip, Input, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { CSSProperties, useEffect, useState } from 'react'
import { MdOutlineSearch, MdCloudDownload } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const columns = [
  { name: '#', uid: 'id' },
  { name: 'Nombre', uid: 'name' },
  { name: 'Email', uid: 'email' },
  { name: 'Dispositivo', uid: 'sbu' },
  { name: 'Marca', uid: 'brand' },
  { name: 'Serie/imei', uid: 'serie' },
  { name: 'Modelo', uid: 'model' },
  { name: 'Monto Descuento', uid: 'discount_amount' },
  { name: 'Código', uid: 'login_code' },
  { name: 'Estado', uid: 'status' },
  { name: 'Motivo', uid: 'cancel_reason' },
  { name: 'Seller', uid: 'seller' },
  { name: '', uid: 'actions' }
]

const excelName = 'Listado_TradeIn_Principal'

const TablePrincipal = () => {
  const [data, setData] = useState<{ loading: boolean, list: any[] }>({ loading: false, list: [] })
  const {
    sortDescriptor,
    filterValue,
    sortedItems,
    bottomContent,
    onSearchChange,
    setSortDescriptor,
    setPage,
    onClear,
    exportToCSV
  } = useTableData(columns, excelName, data.list)
  const { slug } = useParams()

  const userState = useSelector((store: any) => store.user)

  const loadData = async () => {
    setData({ ...data, loading: true })
    await webApiService.getListTradeIn({
      email: userState.email,
      poolId: String(import.meta.env.VITE_COGNITO_POOL_ID),
      listStatus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11],
      id_role: userState.id_role
    }).then((response) => {
      setData({ loading: false, list: response.data })
      setPage(1)
    })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCell: any = (item: any, columnKey: any) => {
    const cellValue = item[columnKey]

    /* eslint default-case: "error" */
    switch (columnKey) {
      case 'discount_amount':
        return (<span className='font-bold text-xs'>{currencyFormat(Number(cellValue))}</span>)
      case 'name':
        return (<span className='font-bold text-xs'>{item[columnKey] + ' ' + item.lastname}</span>)
      case 'status':
        return (<Chip color='primary' style={{ '--status-color': `${StatusColor(cellValue)}`, backgroundColor: 'var(--status-color)' } as CSSProperties} size='sm' variant='solid' className=''>{cellValue}</Chip>)
      case 'actions':
        return (<Button size='sm' as={Link} href={PrivateRoutes.TRADEIN.replace(':slug', String(slug)).replace(':id', item.id)} className='bg-black text-white'>Revisar</Button>)
      default:
        return (<span className='text-xs'>{cellValue}</span>)
    }
  }

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='space-y-4'
    >
      <div className='grid grid-cols-2'>
        <div className='my-4'>
          <Input
            label='Search'
            isClearable
            radius='lg'
            value={filterValue}
            onValueChange={onSearchChange}
            onClear={() => onClear()}
            classNames={{
              label: 'text-black/50 dark:text-white/90',
              input: [
                'bg-transparent',
                'text-black/90 dark:text-white/90',
                'placeholder:text-default-700/50 dark:placeholder:text-white/60'
              ],
              innerWrapper: 'bg-transparent',
              inputWrapper: [
                'bg-default-200/50',
                'dark:bg-default/60',
                'backdrop-blur-xl',
                'backdrop-saturate-200',
                'hover:bg-default-200/70',
                'dark:hover:bg-default/70',
                'group-data-[focus=true]:bg-default-200/50',
                'dark:group-data-[focus=true]:bg-default/60',
                '!cursor-text'
              ]
            }}
            placeholder='Buscar...'
            startContent={
              <MdOutlineSearch className='text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0' />
        }
          />
        </div>
        <div className='flex items-center justify-end'>
          <Button onClick={exportToCSV} color='success' className='text-white' variant='shadow' endContent={<MdCloudDownload />}>Descargar</Button>
        </div>
      </div>
      <Table
        aria-label='Example table with client side sorting'
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        bottomContent={bottomContent}
        bottomContentPlacement='outside'
      >
        <TableHeader columns={columns.filter(e => e.uid !== 'serie' && e.uid !== 'cancel_reason')}>
          {(column) => (
            <TableColumn key={column.uid} align={column.uid === 'actions' ? 'center' : 'start'} allowsSorting={column.uid !== 'actions'} className={column.uid === 'nombre' || column.uid === 'actions' || column.uid === 'ordenCompraCodigo' ? '' : 'md:table-cell'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={data.loading}
          loadingContent={<Spinner label='Loading...' />}
        >
          {
            sortedItems.map((item: any) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </motion.div>
  )
}

export default TablePrincipal
