import { Dispatch, SetStateAction } from 'react'

export interface dataForComponents {
  key: string,
  label: string
  visible?: number[]
}

export interface rowForTable {
  key: number
  brand: string
  model: string
  cpu?: string
  cpuGeneration?: string
  screen?: string
  ram?: string
  capacity: string
  discount: number
}

export interface State {
  products: Array<rowForTable>
  allSbus: Array<dataForComponents>
  sbuForTable: dataForComponents
  file: File | null
  isLoading: boolean
}

export interface UploadExcelProps {
  state: State
  setState: Dispatch<SetStateAction<State>>
}

export interface UploadExcelState {
  state: State
}

export const columnsForTable: dataForComponents[] = [
  {
    key: 'key',
    label: 'ÍNDICE',
    visible: [1, 2, 3, 5]
  },
  {
    key: 'brand',
    label: 'MARCA',
    visible: [1, 2, 3, 5]
  },
  {
    key: 'model',
    label: 'MODELO',
    visible: [1, 2, 3, 5]
  },
  {
    key: 'cpu',
    label: 'CPU',
    visible: [2]
  },
  {
    key: 'cpuGeneration',
    label: 'GENERACIÓN DEL CPU',
    visible: [2]
  },
  {
    key: 'screen',
    label: 'PANTALLA',
    visible: [2]
  },
  {
    key: 'ram',
    label: 'MEMORIA RAM',
    visible: [2]
  },
  {
    key: 'capacity',
    label: 'CAPACIDAD',
    visible: [1, 2, 3]
  },
  {
    key: 'gpu',
    label: 'GPU',
    visible: [2]
  },
  {
    key: 'discount',
    label: 'MONTO DE DESCUENTO',
    visible: [1, 2, 3, 5]
  }
]
