import React, { useEffect, useState } from 'react'
import UploadExcel from './components/UploadExcel'
import PricesTable from './components/PricesTable'
import { State } from '@/models/uploadPrices'
import SelectSbu from './components/SelectSbu'
import { webApiService } from '@/services'
import { useParams } from 'react-router-dom'
import UploadData from './components/UploadData'
import { Spinner } from '@nextui-org/react'

const UploadPrices: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const [state, setState] = useState<State>({
    products: [],
    allSbus: [],
    sbuForTable: { key: '', label: '' },
    file: null,
    isLoading: false
  })

  console.log('state => ', state)

  useEffect(() => {
    const getAllSbu = async () => {
      console.log('slug => ', slug)
      try {
        const sbusFromDB: any = await webApiService.getListSbu()
          .then((res) => res.data.map((sbu: any) => ({ key: String(sbu.id), label: sbu.description })))

        console.log('sbusFromDB => ', sbusFromDB)

        setState(prevState => ({ ...prevState, allSbus: sbusFromDB }))
      } catch (error) {
        console.error('Error al traer los SBUs:', error)
      }
    }
    getAllSbu()
  }, []) // eslint-disable-line

  return (
    <div className='space-y-4'>
      {state.allSbus.length > 0 &&
        <>
          <div className='flex items-center gap-10'>
            <SelectSbu state={state} setState={setState} />
            <UploadExcel state={state} setState={setState} />
            <UploadData state={state} setState={setState} />
            {state.isLoading && <Spinner label='Cargando...' size='md' className='flex-row items-center' />}
          </div>
          {state.sbuForTable.key !== '' &&
            <PricesTable state={state} />}
        </>}
    </div>
  )
}

export default UploadPrices
