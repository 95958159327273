import { useParams } from 'react-router-dom'
import { webApiService } from '@/services'
import { ChangeEvent, useState } from 'react'
import { Button as ButtonNext } from '@nextui-org/react'
import { Box, FormControl, FormControlLabel, FormLabel, Modal, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useTradeinContext } from '../hooks/useTradeinContext'
import NUIAlert from '@/components/Alert/Alert'
import { useSelector } from 'react-redux'
import { IConfigSeller } from '@/models/configSeller'

const Questions = () => {
  const { id } = useParams()
  const { ctx, saveCtx } = useTradeinContext()
  const [data, setData] = useState<{ loading: boolean, list: any[], questionList: any[], answers: any[], openModal: boolean }>({
    loading: false,
    list: [],
    questionList: [],
    answers: [],
    openModal: false
  })
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'

  // console.log('data => ', data)

  // const loadData = (): void => {
  //   setData({ ...data, loading: true })
  //   webApiService.getAllAnswers(String(id)).then(res => {
  //     setData({ ...data, loading: false, list: res.data })
  //   })
  //     .catch((error) => {
  //       console.log('Error loading data:', error)
  //     })
  // }

  // useEffect(() => {
  //   loadData()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id])

  const getAllQuestions = async () => {
    setData({ ...data, loading: true })
    webApiService.getAllQuestions().then(res => {
      if (res.success) {
        setData({ ...data, questionList: res.data, openModal: true, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleChangeQuestion = (event: ChangeEvent<HTMLInputElement>, idPregunta: number) => {
    console.log('🚀 ~ handleChangeQuestion ~ arrResp:', data.questionList, (event.target as HTMLInputElement).value)

    const arr = [...data.answers]
    console.log('🚀 ~ handleChangeQuestion ~ arr:', arr)

    if (arr.some(e => e.id_question === idPregunta)) {
      const idx = arr.findIndex(e => e.id_question === idPregunta)
      console.log('🚀 ~ handleChangeQuestion ~ idx:', idx)
      arr.splice(idx, 1)
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    } else {
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    }
    console.log('Entro', data.answers.length, data.questionList.filter(q => q.id_parent_question === null).length)
    setData({ ...data, answers: arr })
  }

  const handleClose = () => {
    setData({ ...data, openModal: false, loading: false, answers: [] })
  }

  const handleSave = async () => {
    setData({ ...data, loading: true })
    try {
      const res = await webApiService.saveFormAnswers({ tradeinId: id, answers: data.answers })
      console.log('res', res)
      setData({ ...data, loading: false, answers: [], openModal: false })
      saveCtx({ ...ctx, reload: true })
      // loadData()
    } catch (error: any) {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    }
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <div className='border-b-1 mb-2 font-bold text-xl pb-2 flex justify-between'>
        <h6>Preguntas evaluación</h6>
        {isSamsungPeru && ctx.formAnswers.length === 0 && ctx.tradein.certificate_theft !== null &&
          <>
            <ButtonNext
              color='primary'
              onClick={getAllQuestions}
              isLoading={data.loading}
            >
              Preguntas
            </ButtonNext>
            <Modal open={data.openModal} onClose={handleClose}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  p: 2
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '800px',
                    backgroundColor: 'white',
                    borderRadius: 2,
                    textAlign: 'center',
                    maxHeight: '90vh',
                    overflowY: 'auto'
                  }}
                >
                  <Typography
                    id='modal-title'
                    variant='h6'
                    component='h2'
                    sx={{
                      backgroundColor: '#333',
                      color: '#fff',
                      width: '100%',
                      padding: 2
                    }}
                  >
                    Evalúa tu equipo
                  </Typography>
                  <Paper variant='outlined' sx={{ padding: 2 }} className='small'>
                    {
                    data.questionList.filter((q :any) => q.id_parent_question === null).map((p:any, i: number) => {
                      return (
                        <div className='mb-2 p-2 rounded shadow' key={i}>
                          <FormControl className='text-start w-100'>
                            <FormLabel id='demo-row-radio-buttons-group-label' className='small'>{p.questions}</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              name={'radio-group-' + (i)}
                              onChange={(e) => handleChangeQuestion(e, p.id)}
                              value={data.answers.find(a => a.id_question === p.id)?.id_answer}
                            >
                              {
                                data.questionList.filter((a: any) => Number(a.id_parent_question) === p.id).map((a:any, n: number) => {
                                  return (<FormControlLabel key={n} value={a.id} control={<Radio size='small' />} label={a.questions} className='small' />)
                                })
                            }
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )
                    })
                }

                  </Paper>
                  <Box
                    sx={{
                      backgroundColor: '#f1f1f1',
                      width: '100%',
                      padding: 2,
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2
                    }}
                  >
                    <ButtonNext
                      color='danger'
                      onClick={handleClose}
                      disabled={data.loading}
                    >
                      Cerrar
                    </ButtonNext>
                    <ButtonNext
                      isLoading={data.loading} color='primary' type='submit' isDisabled={data.questionList.filter((q :any) => q.id_parent_question === null).length !== data.answers.length}
                      onClick={handleSave}
                    >
                      Guardar
                    </ButtonNext>
                  </Box>
                </Box>

              </Box>
            </Modal>
          </>}
      </div>
      <div>
        {isSamsungPeru && ctx.tradein.certificate_theft === null
          ? <NUIAlert type='info'>Se requiere el resultado de la evaluación técnica para realizar el formulario.</NUIAlert>
          : isSamsungPeru && ctx.formAnswers.length === 0
            ? <NUIAlert type='info'>Complete el formulario para poder continuar.</NUIAlert>
            : ctx.formAnswers.map((item: any, index: number) => (
              <div key={index}>
                <p className=''>{item.question}</p>
                <p className='fw-bold font-bold mb-2'>{item.answer}</p>
              </div>
            ))}
      </div>
    </div>
  )
}

export default Questions
