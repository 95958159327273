import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

import React, { useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { useSnackbar } from 'notistack'

import EyeFilledIcon from '@/assets/svg/EyeFilledIcon.svg?react'
import EyeSlashFilledIcon from '@/assets/svg/EyeSlashFilledIcon.svg?react'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import { IModalProps } from '@/models/ModalProps'

const ModalForgotPassword: React.FC<IModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{ loading: boolean, email: string, password: string, confirmPassword: string, stage: number, code: any, showPassword: boolean, showConfirmPassword: boolean }>({
    loading: false,
    email: '',
    password: '',
    confirmPassword: '',
    stage: 1,
    code: '',
    showPassword: false,
    showConfirmPassword: false
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = (): void => {
    setModal({ ...modal, visible: false })
  }

  // const handleChange = (e:any) => {
  //   e.preventDefault()
  // }

  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false
  })

  const handlePasswordChange = (value: string): void => {
    const newPassword = value

    setPasswordRequirements({
      minLength: newPassword.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasLowerCase: /[a-z]/.test(newPassword),
      hasNumber: /[0-9]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*()_+{}[\]:;<>,.?~-]/.test(newPassword)
    })

    setData({ ...data, password: newPassword })
  }

  const handleConfirmPasswordChange = (value: string): void => {
    setData({ ...data, confirmPassword: value })
  }

  const togglePasswordVisibility = (): void => {
    setData({ ...data, showPassword: !data.showPassword })
  }

  const toggleConfirmPasswordVisibility = (): void => {
    setData({ ...data, showConfirmPassword: !data.showConfirmPassword })
  }

  const userPool = new CognitoUserPool({
    UserPoolId: String(import.meta.env.VITE_COGNITO_POOL_ID),
    ClientId: String(import.meta.env.VITE_COGNITO_WEB_CLIENT_ID)
  })
  const cognitoUser = new CognitoUser({
    Username: data.email,
    Pool: userPool
  })

  const setSuccess = (): void => {
    if (data.email === '') {
      enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
      return
    }
    const promise = new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result)
          setData({ ...data, stage: 2 })
          enqueueSnackbar('Se ha enviado un código de verificación a su correo para crear su nueva contraseña.', { variant: 'success' })
        },
        onFailure: function (err) {
          console.log(err.message)
          reject(err)
          if (err.message === 'Attempt limit exceeded, please try after some time.') {
            enqueueSnackbar('Excediste el número de intentos, por favor, intente en 1 hora ...', { variant: 'error' })
          }
        }
      })
    })
    console.log('🚀 ~ promise ~ promise:', promise)
    // return await promise
  }

  const resetPassword = (): void => {
    if (data.password === '' || data.confirmPassword === '') {
      enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
      return
    }
    if (data.password !== data.confirmPassword) {
      enqueueSnackbar('Las contraseñas no coinciden, por favor, intente nuevamente', { variant: 'error' })
      return
    }
    const promise = new Promise((resolve, _reject) => {
      console.log('🚀 ~ promise ~ _reject:', _reject)
      cognitoUser.confirmPassword(data.code as string, data.confirmPassword, {
        onFailure: (err) => {
          console.log('onFailure : ', err)
          if (err.name === 'InvalidPasswordException') {
            enqueueSnackbar('La contraseña no cumple con los requisitos. Por favor, intente nuevamente.', { variant: 'error' })
          } else {
            enqueueSnackbar('Hubo un error.' + err.message, { variant: 'error' })
          }
        },
        onSuccess: (res) => {
          console.log('onSuccess : ', res)
          resolve(res)
          enqueueSnackbar('Nueva contraseña actualizada exitosamente, por favor, ingrese con sus nuevas credenciales', { variant: 'success' })
          handleClose()
          /*
          setTimeout(() => {
            window.location.replace('')
          }, 3000)
          */
        }
      })
    })
    console.log('🚀 ~ promise ~ promise:', promise)
  }

  return (
    <Modal backdrop='blur' isOpen={modal.visible} onClose={handleClose}>
      <ModalContent>
        <ModalHeader className='flex flex-col gap-1'>{data.stage === 1 ? 'Recuperar Contraseña' : 'Actualizar Nueva Contraseña'}</ModalHeader>
        <ModalBody>
          <div className='row gy-2'>
            <div className='col-12 my-3'>
              {data.stage === 1 && (
                <div>
                  <div>
                    <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Ingrese su email</label>
                    <Input type='email' name='email' value={data.email} onValueChange={(value) => { setData({ ...data, email: value }) }} />
                  </div>
                  <div className='col-12 text-center'>
                    <Button
                      type='submit'
                      fullWidth
                      onClick={setSuccess}
                      className='mt-3 mb-2 pt-1.3 pb-1.3 w-200'
                    >
                      Recuperar contraseña
                    </Button>
                  </div>
                </div>
              )}
              {data.stage === 2 && (
                <div>
                  <div className='mb-3'>
                    <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Ingrese su código de verificación</label>
                    <Input type='email' name='email' value={data.code} onValueChange={(value) => { setData({ ...data, code: value }) }} />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Ingrese su contraseña</label>
                    <Input
                      type={data.showPassword ? 'text' : 'password'}
                      name='password'
                      value={data.password}
                      onValueChange={handlePasswordChange}
                      endContent={
                        <button className='focus:outline-none' type='button' onClick={togglePasswordVisibility} aria-label='toggle password visibility'>
                          {data.showPassword ? <EyeSlashFilledIcon /> : <EyeFilledIcon />}
                        </button>
                        }
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Confirme nueva contraseña</label>
                    <Input
                      type={data.showConfirmPassword ? 'text' : 'password'}
                      name='confirmPassword'
                      value={data.confirmPassword}
                      onValueChange={handleConfirmPasswordChange}
                      endContent={
                        <button className='focus:outline-none' type='button' onClick={toggleConfirmPasswordVisibility} aria-label='toggle password visibility'>
                          {data.showConfirmPassword ? <EyeSlashFilledIcon /> : <EyeFilledIcon />}
                        </button>
                        }
                    />
                  </div>
                  <div className='bg-primary-100 border-primary-500 border-1 rounded-xl p-3'>
                    <h4 className='text-lg font-bold'>Tu contraseña debe incluir al menos:</h4>
                    <div className='flex items-center'>
                      {passwordRequirements.minLength ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
                      <span>Mínimo 8 caracteres</span>
                    </div>
                    <div className='flex items-center'>
                      {passwordRequirements.hasUpperCase ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
                      <span>Una mayúscula</span>
                    </div>
                    <div className='flex items-center'>
                      {passwordRequirements.hasLowerCase ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
                      <span>Una minúscula</span>
                    </div>
                    <div className='flex items-center'>
                      {passwordRequirements.hasNumber ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
                      <span>Un número</span>
                    </div>
                    <div className='flex items-center'>
                      {passwordRequirements.hasSpecialChar ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
                      <span>Un caractér especial</span>
                    </div>
                  </div>
                  <div className='col-12 text-center'>
                    <Button
                      type='submit'
                      color='primary'
                      fullWidth
                      isDisabled={!passwordRequirements.minLength || !passwordRequirements.hasUpperCase || !passwordRequirements.hasLowerCase || !passwordRequirements.hasNumber || !passwordRequirements.hasSpecialChar || data.password !== data.confirmPassword || data.code === ''}
                      onClick={resetPassword}
                      className='mt-3 mb-2 pt-1.3 pb-1.3'
                    >
                      Guardar
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalForgotPassword
