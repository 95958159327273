export const getMonto: (data: any) => number = (data) => {
  const sumaPeso = data.answers.reduce((valorAnterior: any, valorActual: any) => { return valorAnterior + valorActual.weigth }, 0)

  if (sumaPeso >= 100) {
    return 0
  }

  const resultado = data.capacity.id_capacity > 0 ? data.capacity.amount - ((data.capacity.amount * sumaPeso) / 100) : data.model.amount - ((data.model.amount * sumaPeso) / 100)

  return resultado
}
