import { webApiService } from '@/services'
import { Icon } from '@/utils/Icons.utility'
import { StatusColor } from '@/utils/StatusColor.utility'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTradeinContext } from '../hooks/useTradeinContext'

const History = () => {
  const [data, setData] = useState<{ loading: boolean, statusHistory: any[] }>({ loading: false, statusHistory: [] })
  const { id } = useParams()
  const { ctx } = useTradeinContext()

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getHistoryById(String(id)).then(res => {
      setData({ loading: true, statusHistory: res.data })
    })
      .catch((error) => {
        setData({ ...data, loading: false })
        console.log('Error loading data:', error)
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (ctx.reload) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.reload])

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-6 font-bold text-xl pb-2'>Historial de Estados</h6>
      {data.statusHistory.map((row: any, index: number) => (
        <div key={index} className='grid grid-cols-5'>
          <div className={index % 2 === 0 ? 'text-xs text-gray-400 col-span-2 flex justify-center items-center order-3' : 'text-xs text-gray-400 col-span-2 flex justify-center items-center'}>{moment(row.created_at).format('DD-MM-YYYY HH:mm')}</div>
          <div className={index % 2 === 0 ? 'rounded-full w-[35px] h-[35px] text-white text-xl flex justify-center items-center mx-auto order-2' : 'rounded-full w-[35px] h-[35px] text-white text-xl flex justify-center items-center mx-auto'} style={{ backgroundColor: StatusColor(row.status) }}>{Icon(row.status)}</div>
          <div className={index % 2 === 0 ? 'col-span-2 flex items-center justify-center order-1' : 'col-span-2 flex items-center justify-center'}>{row.status}</div>
          {data.statusHistory.length !== (index + 1) ? <div className='col-span-5 flex justify-center order-4 my-3'><div className='w-[2px] h-[12px] bg-gray-300' /></div> : null}
        </div>
      ))}
    </div>
  )
}

export default History
