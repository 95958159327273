import AnimateText from '@/components/AnimateText/AnimateText'
import { PublicRoutes } from '@/constants/routes'
import { IConfigSeller } from '@/models/configSeller'
import { terms } from '@/utils/terms'
import { Button, Link } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { MdArrowForward, MdCheckCircleOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const SuccessPage = () => {
  const { slug } = useParams()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='flex flex-col h-svh min-h-[600px]'
    >
      <header className='py-4 container  max-w-[900px] mb-4 mx-auto px-4 md:px-0'>
        <AnimateText text='¡Sus datos fueron enviados con éxito!' className='text-4xl font-bold mb-5' />
      </header>
      <section className='container mx-auto max-w-[900px]'>
        <div className='border-1 border-[#43A047] p-3 rounded-md bg-[#EEFFEF]'>
          <div className='flex'>
            <div className='px-2 pt-[2px]'>
              <MdCheckCircleOutline className='text-xl text-[#43A047] ' />
            </div>
            <div className='col-span-11'>
              <p className='font-bold'>Tus siguientes pasos:</p>
              <ul className='list-disc ps-4 pt-2'>
                <li>Revisa tu correo electrónico y sigue las instrucciones</li>
              </ul>
            </div>
          </div>
        </div>
        {configSeller.config.terms !== undefined &&
          configSeller.config.terms !== '' &&
          slug === 'falabella' &&
          terms(configSeller.config.terms, false)}
      </section>
      <footer>
        <div className='container mx-auto text-center max-w-[900px]'>
          <div className='text-end mt-4'>
            <Button
              as={Link}
              className='rounded-full bg-black text-white disabled:bg-gray-300 disabled:border-gray-300 border-1 py-6 px-10'
              href={PublicRoutes.SLUG.replace(':slug', String(slug))}
              endContent={<MdArrowForward />}
            >
              Realiza una nueva preevaluación
            </Button>
          </div>
        </div>
      </footer>
    </motion.div>
  )
}

export default SuccessPage
