import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import { FC } from 'react'
import LogoReuse from '../../../../assets/images/logo-reuse-alargado.png'
import { UTCDateToCountryDate } from '@/utils/countryDate.util'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%'
  },
  section: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  sectionFirma: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  bloqueFirma: {
    borderTop: 1,
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Times-Bold',
    paddingTop: '5px'
  },
  image: {
    width: '100%'
  },
  viewer: {
    width: '100%'
  },
  table: {
    margin: 10,
    width: '100%',
    fontSize: '8px !important'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '10px',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },
  header: {
    backgroundColor: '#f7f7ff',
    borderTop: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  col1: {
    width: '5%'
  },
  col2: {
    width: '10%'
  },
  col3: {
    width: '10%'
  },
  col4: {
    width: '10%'
  },
  col5: {
    width: '10%'
  },
  col6: {
    width: '10%'
  },
  col7: {
    width: '10%'
  },
  col8: {
    width: '10%'
  },
  col9: {
    width: '10%'
  },
  col10: {
    width: '10%'
  },
  col11: {
    width: '5%'
  },
  generalCol: {
    paddingLeft: 4,
    paddingRight: 4
  }
})

interface IOwnProps {
    firma: string
    detalle: any
    listado: any[]
}

const formatSerie = (serie: any) => {
  if (serie.length > 8) {
    const firstPart = serie.slice(0, 8)
    const secondPart = serie.slice(8)

    return `${firstPart}\n${secondPart}`
  }

  return serie
}

const ManifiestoPickupPDF : FC<IOwnProps> = ({ firma, detalle, listado }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={{ ...styles.section, width: '120px' }}>
          <Image
            style={{ width: '120px' }}
            src={LogoReuse}
          />
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: 14, textAlign: 'left', marginBottom: '15px' }}>Estimado,</Text>
          <Text style={{ fontSize: 14 }}>Se ha realizado un retiro de equipos, acontinuación el detalle de equipos y quién retiro:</Text>
        </View>
        <View style={{ ...styles.section, marginBottom: '15px' }}>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Encargado Retiro</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {detalle.name} {detalle.lastName} </Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Fecha de Retiro</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {UTCDateToCountryDate(moment(new Date()).format())}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={{ ...styles.row, ...styles.bold, ...styles.header }}>
            <Text style={styles.col1}>#</Text>
            <Text style={styles.col2}>Equipo</Text>
            <Text style={styles.col3}>Serie/IMEI</Text>
            <Text style={styles.col4}>Folio</Text>
            <Text style={styles.col5}>Sello</Text>
            <Text style={styles.col6}>Sello2</Text>
            <Text style={styles.col7}>Peso Sello</Text>
            <Text style={styles.col8}>Encargado custodia</Text>
            <Text style={styles.col9}>Fecha custodia</Text>
            <Text style={styles.col10}>Tienda</Text>
            <Text style={styles.col11}>Capacidad</Text>
          </View>
          {listado !== null && listado.map((row, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={styles.col1}>
                <Text style={styles.bold}>{row.id}</Text>
              </Text>
              <Text style={{ ...styles.col2, ...styles.generalCol }}>{row.sbu + ' ' + row.brand + ' ' + row.model}</Text>
              <Text style={{ ...styles.col3, ...styles.generalCol }}>{formatSerie(row.serie)}</Text>
              <Text style={{ ...styles.col4, ...styles.generalCol }}>{formatSerie(row.folio)}</Text>
              <Text style={{ ...styles.col5, ...styles.generalCol }}>{formatSerie(row.seal)}</Text>
              <Text style={{ ...styles.col6, ...styles.generalCol }}>{formatSerie(row.seal2)}</Text>
              <Text style={{ ...styles.col7, ...styles.generalCol }}>{formatSerie(row.seal_weight)}</Text>
              <Text style={{ ...styles.col8, ...styles.generalCol }}>{row.custody_name}</Text>
              <Text style={{ ...styles.col9, ...styles.generalCol }}>{UTCDateToCountryDate(row.custody_date)}</Text>
              <Text style={{ ...styles.col10, ...styles.generalCol }}>{row.store}</Text>
              <Text style={{ ...styles.col11, ...styles.generalCol }}>{row.capacity}</Text>
            </View>
          ))}
        </View>

        <View style={styles.sectionFirma}>
          <View style={{ width: '50%' }}>
            <Text />
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={firma}
            />
            <Text style={styles.bloqueFirma}>Firma encargado retiro</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ManifiestoPickupPDF
