import { FC, ReactNode } from 'react'
import { MdInfo, MdCheckCircle, MdOutlineWarning, MdCancel } from 'react-icons/md'

interface IOwnProps {
    type: 'success' | 'info' | 'warning' | 'error'
    children: ReactNode
}

const NUIAlert: FC<IOwnProps> = ({ type, children }) => {
  if (type === 'success') {
    return (
      <div className='bg-green-50 p-4 border-1 border-green-500 flex rounded-md my-1'>
        <div className='pe-3'>
          <MdCheckCircle className='text-green-500 text-2xl' />
        </div>
        <div className='text-green-800'>{children}</div>
      </div>
    )
  }

  if (type === 'info') {
    return (
      <div className='bg-blue-200 p-4 border-1 border-blue-500 flex rounded-md my-1'>
        <div className='pe-3'>
          <MdInfo className='text-blue-500 text-2xl' />
        </div>
        <div className='text-blue-800'>{children}</div>
      </div>
    )
  }

  if (type === 'warning') {
    return (
      <div className='bg-yellow-100 p-4 border-1 border-yellow-300 flex rounded-md my-1'>
        <div className='pe-3'>
          <MdOutlineWarning className='text-yellow-500 text-2xl' />
        </div>
        <div className='text-yellow-800'>{children}</div>
      </div>
    )
  }

  return (
    <div className='bg-red-100 p-4 border-1 border-red-300 flex rounded-md my-1'>
      <div className='pe-3'>
        <MdCancel className='text-red-500 text-2xl' />
      </div>
      <div className='text-red-800'>{children}</div>
    </div>
  )
}

export default NUIAlert
