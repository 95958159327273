import { Card, CardBody, Spinner } from '@nextui-org/react'
import { FC, ReactNode } from 'react'

interface IOwnProps {
    loading:boolean
    color: string
    indicador: number
    estado: string
    textColor: string
    Icon: ReactNode
}

const StatusCard: FC<IOwnProps> = ({ loading, color, indicador, estado, textColor, Icon }) => {
  return (
    <Card style={{ backgroundColor: color }}>
      <CardBody className='flex flex-row justify-between'>
        <div className='text-center'>
          {loading
            ? (
              <Spinner size='lg' />
              )
            : (
              <p style={{ fontSize: '2rem' }}>{indicador}</p>
              )}
          <div>
            <p style={{ color: textColor, fontSize: '18px' }}>
              {estado}
            </p>
          </div>

        </div>
        {Icon}
      </CardBody>
    </Card>
  )
}

export default StatusCard
