export const PublicRoutes = {
  ROOT: '/',
  SLUG: '/:slug',
  SUCCESS: '/:slug/success',
  LOGIN: '/:slug/backoffice/login',
  NOTFOUND: '/not-found',
  CONTRACT: '/:slug/contract/:loginCode',
  BANKACCOUNT: '/:slug/bank-account/:idContract'
}

export const PrivateRoutes = {
  HOME: '/:slug/backoffice/',
  SUPPLY: '/:slug/backoffice/solicitar-insumos',
  RECIBIDOS: '/:slug/backoffice/recibidos',
  ENCUSTODIA: '/:slug/backoffice/custodia',
  RETIRADOS: '/:slug/backoffice/retirados',
  TRADEIN: '/:slug/backoffice/trade-in/:id',
  PICKUP_FORM: '/:slug/backoffice/pickup-form',
  MAILING: '/:slug/admin/mailing', // solo para falabella chile
  LOADSAMSUNGDATA: '/:slug/admin/bulk-data', // solo para peru samsung
  DATABANK: '/:slug/backoffice/data-bank', // solo para peru samsung
  SUBIR_PRECIOS: '/:slug/backoffice/subir-precios'
}
