import { webApiService } from '@/services'
import { Button } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { useTradeinContext } from '../hooks/useTradeinContext'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IAppStore } from '@/models/store.model'

const InWarehouse = () => {
  const [data, setData] = useState<{ loading: boolean }>({ loading: false })
  const { ctx, saveCtx } = useTradeinContext()
  const { id, slug } = useParams()
  const userState = useSelector((store: IAppStore) => store.user)

  const handleInWarehouse = () => {
    setData({ ...data, loading: true })
    webApiService.inWarehouse({ id_tradein: id, seller: slug, id_user: userState.id }).then((res) => {
      console.log('🚀 ~ webApiService.inWarehouse ~ res:', res)
      enqueueSnackbar('Recibido en bodega.', { variant: 'success' })
      setData({ ...data, loading: false })
      saveCtx({ ...ctx, reload: true })
    }).catch((error) => {
      enqueueSnackbar('Hubo un error al rechazar', { variant: 'error' })
      setData({ ...data, loading: false })
      console.log('error ==>', error)
    })
  }
  return (
    <Button
      variant='shadow'
      size='sm'
      color='success'
      className='ml-2'
      endContent={<MdCheck style={{ fontSize: '12px' }} />}
      onClick={handleInWarehouse}
    >
      Recibido en Bodega
    </Button>
  )
}

export default InWarehouse
