import { webApiService } from '@/services'
import { Button } from '@nextui-org/react'
import { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import XLSX from 'xlsx-js-style'

const MailingPage = () => {
  const [data, setData] = useState<{ file: File | null, loading:boolean, list: any[], html: string}>({ file: null, loading: false, list: [], html: '' })

  const accept: Accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    const reader = new FileReader()
    reader.onload = (e) => {
      const _data = e.target?.result
      const workbook = XLSX.read(_data, { type: 'binary' })
      console.log(workbook.SheetNames)
      const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

      setData({ ...data, list: rowObject })
      console.log('🚀 ~ readExcel ~ rowObject', rowObject)
    }
    // file to blob type
    reader.readAsBinaryString(validFiles[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  })

  const sendMails = async () => {
    setData({ ...data, loading: true })
    let html = '----- INICIO -----<br/>'
    for (const item of data.list.filter(item => item.SALDO > 0)) {
      console.log('🚀 ~ sendMails ~ item', item['CODIGO AUTORIZACIÓN'])
      await webApiService.sendMails({ codigo: item['CODIGO AUTORIZACIÓN'] }).then((res) => {
        console.log('🚀 ~ sendMails ~ res', res)
        html += '<p>' + item['CODIGO AUTORIZACIÓN'] + ' - ' + res.data + '</p>'
      }).catch((err) => {
        console.log('🚀 ~ awaitwebApiService.sendMails ~ err:', err)
        html += '<p>' + item['CODIGO AUTORIZACIÓN'] + ' - Email error</p>'
      })
      setData({ ...data, html, loading: true })
      //
    }
    html += '----- FIN -----<br/>'
    setData({ ...data, loading: false, html })
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Mailing Giftcard Falabella</h6>
      <div className='py-4'>
        <p className='text-sm'>Este módulo permite enviar correos a los clientes con giftcards de Falabella</p>
      </div>
      {
        data.list.length > 0
          ? (
            <div>
              <div className='p-6 text-center'>
                <p className='text-xl'>El excel contiene <strong>{data.list.length}</strong> registros de gifcards de los cuales <strong>{data.list.filter(item => item.SALDO > 0).length}</strong> tienen saldo pendiente  </p>
                <div className='mt-4'>
                  <Button size='lg' variant='shadow' color='secondary' isLoading={data.loading} onClick={sendMails}>Realizar envio masivo de email recordatorio de saldo</Button>
                </div>
              </div>
              <div className='bg-black p-2 text-white' dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
            )
          : (
            <div
              {...getRootProps()} style={{
                border: '2px dashed #cccccc',
                borderRadius: '5px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                color: isDragActive ? '#ffffff' : '#000000'
              }}
            >
              <input {...getInputProps()} />
              {
                    isDragActive
                      ? (
                        <p>Arrastre el archivo .XLSX y suelta las aquí...</p>
                        )
                      : (
                        <p>Arrastre y suelte el archivo aquí, o haz clic para seleccionar el archivo</p>
                        )
                  }
              <p>
                (Solo se permite archivo .xlsx)
              </p>
            </div>
            )
      }
    </div>
  )
}

export default MailingPage
