import { Outlet } from 'react-router-dom'
import Header from './Header'
import { Footer } from './Footer'

const Layout = () => {
  return (
    <div className='flex flex-col min-h-screen lg:h-full'>
      <Header />
      <div className='grow bg-[#f7f7ff]'>
        <div className='flex justify-center '>
          <div className='container mx-auto lg:px-[15px] py-[25px] '>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
