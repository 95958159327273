import { IConfigSeller } from '@/models/configSeller'
import { webApiService } from '@/services'
import { currencyFormat } from '@/utils/currencyFormat'
import { Card, CardBody, Select, SelectItem, Skeleton } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React, { CSSProperties, useEffect, useState } from 'react'
import { MdLaptop, MdOutlineTabletMac, MdOutlineWatch, MdSmartphone } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useFormularioClienteContext } from '../hooks/useFormularioClienteContext'
import { useParams } from 'react-router-dom'

const StepOne: React.FC = () => {
  const { ctx, saveCtx } = useFormularioClienteContext()
  const [data, setData] = useState<{ loading: boolean, sbuLoading: boolean, sbuList: any[], brandList: any[], modelList: any[], capacityList: any[], cpuList: any[], gpuList: any[], generationList: any[], screenList: any[], ramList: any[]}>({ loading: false, sbuLoading: false, sbuList: [], brandList: [], modelList: [], capacityList: [], cpuList: [], gpuList: [], generationList: [], screenList: [], ramList: [] })
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const { slug } = useParams<{ slug: string }>()

  const loadData = async () => {
    if (ctx.data.sbu.id_sbu === 2) {
      if (ctx.data.brand.id_brand === 1 && ctx.data.model.capacity !== 0) {
        const res : any = await Promise.all([
          webApiService.getAllSbu(String(slug)),
          webApiService.getAllBrandBySbu(String(ctx.data.sbu.id_sbu), String(slug)),
          webApiService.getAllModelByBrand(String(ctx.data.sbu.id_sbu), String(ctx.data.brand.id_brand), String(slug)),
          webApiService.getDataByModel({ idSbu: String(ctx.data.sbu.id_sbu), idBrand: String(ctx.data.brand.id_brand), idModel: String(ctx.data.model.id_model), seller: String(slug) })
        ]).catch((error) => {
          console.log(error)
          enqueueSnackbar(error.message, { variant: 'error' })
        })

        console.log('res[2] => ', res[2])

        console.log('res => ', res)
        setData({
          ...data,
          sbuList: res[0].data.filter((item: any) => configSeller.config.permisos.SBUS_VISIBLES.includes(item.id_sbu)),
          brandList: res[1].data,
          modelList: res[2].data,
          cpuList: res[3].data.cpu.map((item: any) => { return { ...item, value: item.id, label: item.name } }),
          ramList: res[3].data.ram.map((item: any) => { return { ...item, value: item.id, label: item.ram } }),
          screenList: res[3].data.screen.map((item: any) => { return { ...item, value: item.id, label: item.screen } }),
          capacityList: res[3].data.capacity.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }),
          loading: false
        })
      } else {
        const res : any = await Promise.all([
          webApiService.getAllSbu(String(slug)),
          webApiService.getAllBrandBySbu(String(ctx.data.sbu.id_sbu), String(slug)),
          webApiService.getAllCPU(),
          webApiService.getAllGenerationByCPU(String(ctx.data.cpu.id)),
          webApiService.getAllScreenByCPU(String(ctx.data.cpu.id)),
          webApiService.getAllRamByCPU(String(ctx.data.cpu.id))
          // webApiService.getAllCapacityByCPU(String(ctx.data.cpu.id))
        ]).catch((error) => {
          console.log(error)
          enqueueSnackbar(error.message, { variant: 'error' })
        })

        setData({
          ...data,
          sbuList: res[0].data.filter((item: any) => configSeller.config.permisos.SBUS_VISIBLES.includes(item.id_sbu)),
          brandList: res[1].data,
          cpuList: res[2].data,
          generationList: ctx.data.generation.id_cpu_generation !== 0 ? res[3].data.map((item: any) => { return { ...item, value: item.id_cpu_generation, label: item.cpu_generation } }) : [],
          screenList: ctx.data.screen.id_screen !== 0 ? res[4].data.map((item: any) => { return { ...item, value: item.id_screen, label: item.screen } }) : [],
          ramList: ctx.data.ram.id_ram !== 0 ? res[5].data.map((item: any) => { return { ...item, value: item.id_ram, label: item.ram } }) : [],
          capacityList: ctx.data.capacity.id_capacity !== 0 ? res[6].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }) : []
        })
      }
    } else {
      setData({ ...data, sbuLoading: true, loading: true })

      const res : any = await Promise.all(ctx.data.sbu.id_sbu === 0
        ? [
            webApiService.getAllSbu(String(slug))
          ]
        : [
            webApiService.getAllSbu(String(slug)),
            webApiService.getAllBrandBySbu(String(ctx.data.sbu.id_sbu), String(slug)),
            webApiService.getAllModelByBrand(String(ctx.data.sbu.id_sbu), String(ctx.data.brand.id_brand), String(slug)),
            webApiService.getAllCapacityByModel({ idSbu: String(ctx.data.sbu.id_sbu), idBrand: String(ctx.data.brand.id_brand), idModel: String(ctx.data.model.id_model), seller: String(slug) })
          ]).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
      })

      ctx.data.sbu.id_sbu === 0
        ? setData({ ...data, sbuList: res[0].data.filter((item: any) => configSeller.config.permisos.SBUS_VISIBLES.includes(item.id_sbu)), loading: false, sbuLoading: false })
        : setData({
          ...data,
          loading: false,
          sbuLoading: false,
          sbuList: res[0].data.filter((item: any) => configSeller.config.permisos.SBUS_VISIBLES.includes(item.id_sbu)),
          brandList: ctx.data.sbu.id_sbu !== 0 ? res[1].data : [],
          modelList: ctx.data.model.id_model !== 0 ? res[2].data.map((item: any) => { return { ...item, value: item.id_model, label: item.model } }) : [],
          capacityList: ctx.data.capacity.id_capacity !== 0 ? res[3].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }) : []
        })
    }
  }

  const getIcon = (idSbu: number) => {
    switch (idSbu) {
      case 1:
        return <MdSmartphone className='mx-auto text-3xl' />
      case 2:
        return <MdLaptop className='mx-auto text-3xl' />
      case 3:
        return <MdOutlineTabletMac className='mx-auto text-3xl' />
      case 5:
        return <MdOutlineWatch className='mx-auto text-3xl' />
      default:
        return null
    }
  }

  const onClickSbu = (sbu: any) => {
    console.log('🚀 ~ onClickSbu ~ sbu:', sbu)

    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        sbu,
        brand: { id_brand: 0, discount: '', brand: '', value: '', label: '' },
        model: { id_model: 0, model: '', value: '', label: '', amount: 0, capacity: 0 },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 },
        cpu: { id: 0, name: '', value: '', label: '' },
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' }
      }
    })
    setData({ ...data, loading: true })
    webApiService.getAllBrandBySbu(String(sbu.id_sbu), String(slug)).then(res => {
      if (res.success) {
        setData({ ...data, brandList: res.data, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleBrandChange = (value: any) => {
    console.log('value => ', value)
    value = JSON.parse(value)
    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        brand: value,
        model: { id_model: 0, model: '', value: '', label: '', amount: 0, capacity: 0 },
        cpu: { id: 0, name: '', value: '', label: '' },
        gpu: { id: 0, name: '', value: '', label: '' },
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
      }
    })
    setData({ ...data, loading: true })
    if (ctx.data.sbu.id_sbu === 1 || ctx.data.sbu.id_sbu === 3 || ctx.data.sbu.id_sbu === 5 || (ctx.data.sbu.id_sbu === 2 && value.id_brand === 1)
    // (ctx.data.sbu.id_sbu === 2 && value.id_brand === 2)
    ) {
      console.log(1)
      webApiService.getAllModelByBrand(String(ctx.data.sbu.id_sbu), String(value.id_brand), String(slug)).then(res => {
        if (res.success) {
          setData({
            ...data,
            modelList: res.data.map((item: any) => { return { ...item, value: item.id_model, label: (item.capacity > 0) ? item.model : item.model + ' - Descuento hasta ' + currencyFormat(item.amount) } }),
            gpuList: [],
            capacityList: [],
            loading: false
          })
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    } else {
      console.log(2)
      webApiService.getAllCPU().then(res => {
        if (res.success) {
          setData({ ...data, cpuList: res.data.map((item: any) => { return { ...item, value: item.id, label: item.name } }), loading: false })
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    }
  }

  const handleModelChange = (value: any) => {
    value = JSON.parse(value)
    console.log('🚀 ~ handleModelChange ~ value:', value)
    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        model: value,
        cpu: { id: 0, name: '', value: '', label: '' },
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
      }
    })
    setData({ ...data, loading: true })
    console.log(ctx.data.brand.id_brand, ctx.data.sbu.id_sbu, value.capacity)
    if (ctx.data.brand.id_brand === 1 && ctx.data.sbu.id_sbu === 2 && value.capacity !== 0) {
      webApiService.getDataByModel({ idSbu: String(ctx.data.sbu.id_sbu), idBrand: String(ctx.data.brand.id_brand), idModel: String(value.id_model), seller: String(slug) }).then(res => {
        if (res.success) {
          console.log('res => ', res)
          setData({
            ...data,
            cpuList: res.data.cpu.map((item: any) => { return { ...item, value: item.id, label: item.name } }),
            ramList: res.data.ram.map((item: any) => { return { ...item, value: item.id, label: item.ram } }),
            screenList: res.data.screen.map((item: any) => { return { ...item, value: item.id, label: item.screen } }),
            capacityList: res.data.capacity.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }),
            loading: false
          })
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    } else {
      webApiService.getAllCapacityByModel({ idSbu: String(ctx.data.sbu.id_sbu), idBrand: String(ctx.data.brand.id_brand), idModel: String(value.id_model), seller: String(slug) }).then(res => {
        if (res.success) {
          setData({ ...data, capacityList: res.data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }), loading: false })
          if (res.data.length === 0) {
            saveCtx({
              ...ctx,
              steps: { ...ctx.steps, isValid: true },
              data: {
                ...ctx.data,
                model: value,
                cpu: { id: 0, name: '', value: '', label: '' },
                generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
                screen: { id_screen: 0, screen: '', value: '', label: '' },
                ram: { id_ram: 0, ram: '', value: '', label: '' },
                capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
              }
            })
          }
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    }
  }

  const handleProcesorChange = async (value: any) => {
    if (ctx.data.model.capacity === 0) {
      console.log(value)
      value = JSON.parse(value)
      saveCtx({
        ...ctx,
        steps: { ...ctx.steps, isValid: false },
        data: {
          ...ctx.data,
          cpu: value,
          generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
          screen: { id_screen: 0, screen: '', value: '', label: '' },
          ram: { id_ram: 0, ram: '', value: '', label: '' },
          capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 },
          gpu: { id: 0, name: '', value: '', label: '' }
        }
      })
      setData({ ...data, loading: true })

      const response = await Promise.all([
        webApiService.getAllGenerationByCPU(String(value.id)),
        webApiService.getAllScreenByCPU(String(value.id)),
        webApiService.getAllRamByCPU(String(value.id)),
        webApiService.getAllGPU()
        // webApiService.getAllCapacityByCPU(String(value.id))
      ]).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
      console.log('🚀 ~ handleProcesorChange ~ response:', response)

      if (response !== undefined) {
        setData({
          ...data,
          generationList: response[0].data.map((item: any) => { return { ...item, value: item.id_cpu_generation, label: item.cpu_generation } }).filter((ele :any) => ele.id_cpu_generation !== null),
          screenList: response[1].data.map((item: any) => { return { ...item, value: item.id_screen, label: item.screen } }).filter((ele :any) => ele.id_screen !== null),
          ramList: response[2].data.map((item: any) => { return { ...item, value: item.id_ram, label: item.ram } }).filter((ele :any) => ele.id_ram !== null),
          gpuList: response[3].data.map((item: any) => { return { ...item, value: item.name, label: item.name } }),
          capacityList: [],
          // capacityList: response[3].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + currencyFormat(item.amount) } }),
          loading: false
        })
      }
    } else {
      console.log('value => ', value)
      value = JSON.parse(value)
      saveCtx({ ...ctx, data: { ...ctx.data, cpu: value } })
    }
  }

  const getDiscounts = async (value: any) => {
    value = JSON.parse(value)
    console.log('value =>', value)
    setData({ ...data, loading: true })
    const obj = {
      id_sbu: 2,
      id_brand: ctx.data.brand.id_brand,
      id_cpu: ctx.data.cpu.id,
      id_cpu_generation: ctx.data.generation.id_cpu_generation,
      id_screen: ctx.data.screen.id_screen,
      id_ram: ctx.data.ram.id_ram,
      id_gpu: value.id === 0 ? null : value.id
    }

    console.log('obj => ', obj)

    const res = await webApiService.getAllCapacity(obj).then(res => res.data)
    console.log('res => ', res)
    saveCtx({ ...ctx, data: { ...ctx.data, gpu: value, capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 } } })
    setData({ ...data, capacityList: res, loading: false })
  }

  useEffect(() => {
    loadData()
    return () => setData({ loading: false, sbuLoading: false, sbuList: [], brandList: [], modelList: [], capacityList: [], cpuList: [], gpuList: [], generationList: [], screenList: [], ramList: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className='py-6 px-2 md:px-0'
    >
      <div className='mb-4'>
        <p className='mb-2'>Seleccione su equipo:</p>
        <div className={data.sbuList.length < 4 ? 'flex flex-wrap justify-center gap-8' : 'grid grid-cols-4 gap-4 md:gap-6'}>
          {data.sbuLoading
            ? [1, 2, 3, 4].map(item => (
              <div key={item} className='text-center col-span-2 md:col-span-1'>
                <Card
                  style={{ '--seller-color': `${configSeller.config.color}` } as CSSProperties}
                  className='p-6 max-w-[200px] cursor-pointer text-center border-1 rounded-md border-gray-300 shadow-none min-h-[180px]'
                >
                  <CardBody className='text-center'>
                    <Skeleton className='rounded-lg w-8 mx-auto mb-2'>
                      <div className='h-7 rounded-lg bg-default-300' />
                    </Skeleton>
                    <Skeleton className='rounded-lg mb-1 w-24 mx-auto'>
                      <div className='h-4 rounded-lg bg-default-300 w-2' />
                    </Skeleton>
                    <Skeleton className='rounded-lg mb-1 w-28 mx-auto'>
                      <div className='h-4 rounded-lg bg-default-300 w-2' />
                    </Skeleton>
                    <Skeleton className='rounded-lg w-24 mx-auto'>
                      <div className='h-4 rounded-lg bg-default-300 w-2' />
                    </Skeleton>
                  </CardBody>
                </Card>
              </div>
              ))
            : null}

          {data.sbuList.map(item => (
            <div key={item.id_sbu} className='text-center col-span-2 md:col-span-1 flex justify-center' onClick={() => onClickSbu({ id_sbu: item.id_sbu, name: item.id_sbu === 1 ? 'Smartphone' : item.id_sbu === 2 ? 'Notebook' : item.id_sbu === 3 ? 'Tablet' : item.id_sbu === 5 ? 'Smartwatch' : 'N/D' })}>
              <Card
                style={{ '--seller-color': `${configSeller.config.color}` } as CSSProperties}
                className={ctx.data.sbu.id_sbu === item.id_sbu ? 'p-2 md:p-6 max-w-[200px] cursor-pointer text-center border-1 outline-2 outline-offset-0 outline-[var(--seller-color)] rounded-md border-[var(--seller-color)] shadow-xl min-h-[180px]' : 'py-6 lg:px-6 max-w-[200px] cursor-pointer text-center border-1 rounded-md border-gray-300 shadow-none hover:border-[var(--seller-color)] hover:bg-gray-100 hover:shadow-xl min-h-[180px]'}
              >
                <CardBody className='text-center'>
                  {getIcon(item.id_sbu)}
                  <p className='font-bold'>{item.id_sbu === 1 ? 'Smartphone' : item.id_sbu === 2 ? 'Notebook' : item.id_sbu === 3 ? 'Tablet' : item.id_sbu === 5 ? 'Smartwatch' : 'N/D'}</p>
                  <p>Descuento hasta <span className='font-bold text-xl md:text-2xl'>{currencyFormat(item.max_amount)}</span></p>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div className='max-w-[600px] mx-auto'>
        <div className='mb-4' hidden={ctx.data.sbu.id_sbu === 0}>
          <p className='mb-2'>Seleccione una marca:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.brand.id_brand === 0 ? undefined : [JSON.stringify(ctx.data.brand)]}
            onChange={(value) => { value.target.value === '' ? console.log('seleccion vacia') : handleBrandChange(value.target.value) }}
          >
            {data.brandList.map((brand) => (
              <SelectItem key={JSON.stringify(brand)} textValue={brand.discount !== 0 ? brand.brand + ' - Descuento hasta ' + currencyFormat(brand.discount) : brand.brand}>
                <div className='flex justify-between'>
                  <span className='grow'>{brand.brand}</span>
                  {
                    brand.discount !== null
                      ? (
                        <span className='ml-auto font-bold text-medium w-[205px] text-start'>
                          Descuento hasta {currencyFormat(brand.discount)}
                        </span>)
                      : null

                  }
                </div>
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className='mb-4' hidden={ctx.data.brand.id_brand === 0 || (ctx.data.sbu.id_sbu === 2 && ![1].includes(ctx.data.brand.id_brand))}>
          {/* <div className='mb-4' hidden={ctx.data.brand.id_brand === 0 || (ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand))}> */}
          <p className='mb-2'>Seleccione un modelo:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.model.id_model === 0 ? undefined : [JSON.stringify(data.modelList.find(ele => ele.id_model === ctx.data.model.id_model))]}
            onChange={(value) => { value.target.value === '' ? console.log('seleccion vacia') : handleModelChange(value.target.value) }}
          >
            {data.modelList.map((model) => (
              <SelectItem key={JSON.stringify(model)} textValue={model.capacity === 0 ? model.model + ' - Descuento hasta ' + currencyFormat(model.amount) : model.model}>
                <div className='flex justify-between'>
                  <span className='grow'>{model.model}</span>
                  {
                    model.amount !== 0 && model.capacity === 0
                      ? (
                        <span className='ml-auto font-bold text-medium w-[205px] text-start'>
                          Descuento hasta {currencyFormat(model.amount)}
                        </span>)
                      : null

                  }
                </div>
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Computadores */}
        <div
          className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 ||
            // (ctx.data.sbu.id_sbu === 2 && [2].includes(ctx.data.brand.id_brand)) ||
            ctx.data.brand.id_brand === 0 || (ctx.data.brand.id_brand === 1 && ctx.data.model.id_model === 0) || (ctx.data.model.id_model !== 0 && ctx.data.model.capacity === 0) || data.cpuList.length === 0}
        >
          <p className='mb-2'>Seleccione un procesador:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.cpu.id === 0 ? undefined : [JSON.stringify(ctx.data.cpu)]}
            onChange={(value) => { value.target.value === '' ? console.log('seleccion vacia') : handleProcesorChange(value.target.value) }}
          >
            {data.cpuList.map((cpu) => (
              <SelectItem key={JSON.stringify(cpu)}>
                {cpu.name}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1].includes(ctx.data.brand.id_brand)) || ctx.data.cpu.id === 0}>
          {/* <div className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.cpu.id === 0}> */}
          <p className='mb-2'>Seleccione una generación:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.generation.id_cpu_generation === 0 ? undefined : [JSON.stringify(ctx.data.generation)]}
            onChange={(value) => {
              value.target.value === ''
                ? console.log('seleccion vacia')
                : saveCtx({
                  ...ctx,
                  data: {
                    ...ctx.data,
                    generation: JSON.parse(value.target.value),
                    gpu: { id: 0, name: '', value: '', label: '' },
                    capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
                  }
                })
              if (ctx.data.brand.id_brand !== 1) { setData({ ...data, capacityList: [] }) }
            }}
          >
            {data.generationList.map((gen) => (
              <SelectItem key={JSON.stringify(gen)}>
                {gen.cpu_generation}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div
          className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 ||
          // (ctx.data.sbu.id_sbu === 2 && [2].includes(ctx.data.brand.id_brand)) ||
          (ctx.data.generation.id_cpu_generation === 0 && ctx.data.brand.id_brand !== 1) || (ctx.data.brand.id_brand === 1 && ctx.data.model.id_model === 0) || (ctx.data.brand.id_brand === 1 && ctx.data.model.capacity === 0) || (data.cpuList.length > 0 && ctx.data.cpu.id === 0)}
        >
          <p className='mb-2'>Seleccione pantalla:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.screen.id_screen === 0 ? undefined : [JSON.stringify(ctx.data.screen)]}
            onChange={(value) => {
              value.target.value === ''
                ? console.log('seleccion vacia')
                : saveCtx({
                  ...ctx,
                  data: {
                    ...ctx.data,
                    screen: JSON.parse(value.target.value),
                    gpu: { id: 0, name: '', value: '', label: '' },
                    capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
                  }
                })
              if (ctx.data.brand.id_brand !== 1) { setData({ ...data, capacityList: [] }) }
            }}
          >
            {data.screenList.map((screen) => (
              <SelectItem key={JSON.stringify(screen)}>
                {screen.screen}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div
          className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 ||
          // (ctx.data.sbu.id_sbu === 2 && [2].includes(ctx.data.brand.id_brand)) ||
          ctx.data.screen.id_screen === 0}
        >
          <p className='mb-2'>Seleccione memoria ram:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.ram.id_ram === 0 ? undefined : [JSON.stringify(ctx.data.ram)]}
            onChange={(value) => {
              value.target.value === ''
                ? console.log('seleccion vacia')
                : saveCtx({
                  ...ctx,
                  data: {
                    ...ctx.data,
                    ram: JSON.parse(value.target.value),
                    gpu: { id: 0, name: '', value: '', label: '' },
                    capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
                  }
                })
              if (ctx.data.brand.id_brand !== 1) { setData({ ...data, capacityList: [] }) }
            }}
          >
            {data.ramList.map((ram) => (
              <SelectItem key={JSON.stringify(ram)}>
                {ram.ram}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1].includes(ctx.data.brand.id_brand)) || ctx.data.ram.id_ram === 0}>
          {/* <div className='mb-4' hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.ram.id_ram === 0}> */}
          <p className='mb-2'>Seleccione GPU (Tarjeta Gráfica):</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.gpu.id === 0 ? undefined : [JSON.stringify(ctx.data.gpu)]}
            onChange={(value) => { value.target.value === '' ? console.log('seleccion vacia') : getDiscounts(value.target.value) }}
          >
            {data.gpuList.map((gpu) => {
              return (
                <SelectItem key={JSON.stringify(gpu)}>
                  {gpu.name}
                </SelectItem>
              )
            })}
          </Select>
        </div>
        {/* Fin Computadores */}
        <div className='mb-4' hidden={(ctx.data.model.id_model === 0 && ctx.data.ram.id_ram === 0) || (data.capacityList.length === 0 && ctx.data.brand.id_brand !== 1) || (ctx.data.sbu.id_sbu === 2 && ctx.data.gpu.id === 0 && ctx.data.model.id_model === 1) || (ctx.data.sbu.id_sbu === 2 && ctx.data.ram.id_ram === 0) || ctx.data.sbu.id_sbu === 5}>
          <p className='mb-2'>Seleccione {ctx.data.sbu.id_sbu !== 2 ? 'memoria' : 'almacenamiento'}:</p>
          <Select
            variant='bordered'
            size='lg'
            aria-label='Select'
            scrollShadowProps={{
              isEnabled: true
            }}
            className='w-full'
            isLoading={data.loading}
            isDisabled={data.loading}
            selectedKeys={ctx.data.capacity.id_capacity === 0 ? undefined : [JSON.stringify(ctx.data.capacity)]}
            onChange={(value) => { value.target.value === '' ? console.log('seleccion vacia') : saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: true }, data: { ...ctx.data, capacity: JSON.parse(value.target.value) } }) }}
          >
            {data.capacityList.map((capacity) => (
              <SelectItem key={JSON.stringify(capacity)} textValue={capacity.amount !== 0 ? capacity.capacity + ' - Descuento hasta ' + currencyFormat(capacity.amount) : capacity.capacity}>
                <div className='flex justify-between'>
                  <span className='grow'>{capacity.capacity}</span>
                  {
                    capacity.amount !== 0
                      ? (
                        <span className='ml-auto font-bold text-medium w-[205px]'>
                          Descuento hasta {currencyFormat(capacity.amount)}
                        </span>)
                      : null

                  }
                </div>
              </SelectItem>
            ))}
          </Select>
        </div>
        <div
          className='mb-4' hidden={
            data.loading ||
            ctx.data.brand.id_brand === 0 ||
            ([1, 3, 5].includes(ctx.data.sbu.id_sbu) && ctx.data.model.id_model === 0) ||
            ([1, 3, 5].includes(ctx.data.sbu.id_sbu) && ctx.data.model.id_model !== 0 && data.capacityList.length !== 0 && ctx.data.capacity.id_capacity === 0) ||
            ([2].includes(ctx.data.sbu.id_sbu) && [1].includes(ctx.data.brand.id_brand) && ctx.data.model.id_model === 0 && ctx.data.model.capacity === 0) ||
            ([2].includes(ctx.data.sbu.id_sbu) && [1].includes(ctx.data.brand.id_brand) && ctx.data.model.capacity !== 0 && ctx.data.capacity.id_capacity === 0) ||
            ([2].includes(ctx.data.sbu.id_sbu) && ![1].includes(ctx.data.brand.id_brand) && ctx.data.capacity.id_capacity === 0)
            // ([2].includes(ctx.data.sbu.id_sbu) && [1, 2].includes(ctx.data.brand.id_brand) && ctx.data.model.id_model === 0 && ctx.data.model.capacity === 0) ||
            // ([2].includes(ctx.data.sbu.id_sbu) && [1, 2].includes(ctx.data.brand.id_brand) && ctx.data.model.capacity !== 0 && ctx.data.capacity.id_capacity === 0) ||
            // ([2].includes(ctx.data.sbu.id_sbu) && ![1, 2].includes(ctx.data.brand.id_brand) && ctx.data.capacity.id_capacity === 0)
          }
        >
          <div className='border-2 p-4 rounded-xl'>
            <h3 className='text-xl mb-4'>Resumen de Selección:</h3>
            <p><strong>Dispositivo:</strong> {ctx.data.sbu.name}</p>
            <p><strong>Marca:</strong> {ctx.data.brand.brand}</p>
            {
              ctx.data.sbu.id_sbu === 2 && ctx.data.cpu.name
                ? (
                  <p><strong>Procesador:</strong> {ctx.data.cpu.name}</p>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ctx.data.generation.cpu_generation
                ? (
                  <p><strong>Generación:</strong> {ctx.data.generation.cpu_generation}</p>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ctx.data.screen.screen
                ? (
                  <p><strong>Pantalla:</strong> {ctx.data.screen.screen}</p>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ctx.data.ram.ram
                ? (
                  <p><strong>Memoria ram:</strong> {ctx.data.ram.ram}</p>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ctx.data.gpu.name
                ? (
                  <p><strong>GPU (tarjeta gráfica):</strong> {ctx.data.gpu.name}</p>
                  )
                : null
            }
            {
              ctx.data.model.id_model !== 0
                ? (
                  <p><strong>Modelo:</strong> {ctx.data.model.model}</p>
                  )
                : null
            }
            {
              ctx.data.capacity.id_capacity !== 0
                ? (
                  <p><strong>{ctx.data.sbu.id_sbu !== 2 ? 'Memoria' : 'Almacenamiento'}:</strong> {ctx.data.capacity.capacity}</p>
                  )
                : null
            }
          </div>
        </div>
      </div>

    </motion.div>

  )
}

export default StepOne
