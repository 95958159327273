import { webApiService } from '@/services'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { Document, Page, pdf } from '@react-pdf/renderer'
import { useSnackbar } from 'notistack'
import { FC, useRef, useState } from 'react'
import Html from 'react-pdf-html'
import { useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
interface IOwnProps {
    isOpen: boolean
    onClose: () => void
    html: string
    saveFirma: (firma: string) => void
}

const ModalSign: FC<IOwnProps> = ({ onClose, isOpen, html, saveFirma }) => {
  const [data, setData] = useState<{ loading: boolean }>({ loading: false })
  const signatureCanvasRef = useRef<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { loginCode, slug } = useParams<{ loginCode: string, slug: string }>()

  const clearSignature = async () => {
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  const signContract = async () => {
    if (signatureCanvasRef.current.isEmpty()) {
      enqueueSnackbar('Ingrese su firma', { variant: 'warning' })
      return
    }
    setData({ ...data, loading: true })
    html = html.replace('{{firma}}', '<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')

    // convertir el html a pdf
    const pdfBlob = await pdf(<Document> <Page size='A4' style={{ fontFamily: 'Times-Roman', fontWeight: 'bold' }}> <Html style={{ margin: '40px' }} resetStyles={false}>{html}</Html> </Page></Document>).toBlob()
    console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
    const base64PDF = await blobToBase64(pdfBlob)
    console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

    await webApiService.signContract({ signature: signatureCanvasRef.current.toDataURL('image/png'), pdf: base64PDF, seller: slug, envelopeId: loginCode }).then(res => {
      console.log('🚀 ~ awaitwebApiService.signContract ~ res:', res)
      saveFirma('<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')
      enqueueSnackbar('Contrato firmado.', { variant: 'success' })

      // descargar pdf
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = res.data
      link.download = `${loginCode}.pdf`
      link.click()
      setData({ ...data, loading: false })
    }).catch(error => {
      console.log('🚀 ~ awaitwebApiService.signContract ~ error:', error)
      enqueueSnackbar('Error al firmar', { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      placement='top-center'
      backdrop='blur'
      size='xl'
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>Firmar contrato</ModalHeader>
            <ModalBody>
              <div>
                <p className='text-tiny'>(Firmar en el recuadro)</p>
                <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                </div>
                <div>
                  <Button variant='flat' onPress={clearSignature}>
                    Limpiar firma
                  </Button>
                </div>
              </div>

            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='flat' onPress={onClose}>
                Cancelar
              </Button>
              <Button
                color='primary' onPress={signContract}
                isLoading={data.loading}
              >
                Firmar
              </Button>
            </ModalFooter>
          </>
        )}

      </ModalContent>
    </Modal>
  )
}

export default ModalSign
