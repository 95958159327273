import moment from 'moment'
import { currencyFormat } from './currencyFormat'
import { generateBarcodeBase64 } from './makeBarCode.util'

export const htmlReplaceContract: (html: string, data: any) => string = (html, data) => {
  if (data.seller === 'coppel') {
    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const direccion: string = data.address
    const identificacion: string = data.rut
    const ciudad: string = data.city
    const marca: string = data.brand
    const modelo: string = data.model
    const imei: string = data.imei

    html = html.replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{direccion}}', direccion)
      .replaceAll('{{identificacion}}', identificacion)
      .replaceAll('{{ciudad}}', ciudad)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{imei}}', imei)
  }

  if (data.seller === 'reuse') {
    console.log('🚀 ~ htmlReplaceContract ~ data', data)

    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const identificacion: string = data.rut
    const marca: string = data.brand
    const modelo: string = data.model
    const imei: string = data.imei
    const discount = currencyFormat(Number(data.discount_amount), 'CL')
    const address: string = data.address + ', ' + data.city
    const sbu: string = data.sbu

    html = html.replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{identificacion}}', identificacion)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{imei}}', imei)
      .replaceAll('{{discount}}', discount)
      .replaceAll('{{domicilio}}', address)
      .replaceAll('{{sbu}}', sbu)
  }

  if (data.seller === 'falabella') {
    console.log('🚀 ~ htmlReplaceContract ~ data', data)

    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const identificacion: string = data.rut
    const marca: string = data.brand
    const modelo: string = data.model
    const imei: string = data.imei
    const discount = currencyFormat(Number(data.discount_amount), 'CL')
    const address: string = data.address + ', ' + data.city
    const sbu: string = data.sbu
    const civilState: string = data.civil_state

    html = html.replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{identificacion}}', identificacion)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{imei}}', imei)
      .replaceAll('{{discount}}', discount)
      .replaceAll('{{domicilio}}', address)
      .replaceAll('{{sbu}}', sbu)
      .replaceAll('{{estadoCivil}}', civilState)
  }

  if (data.seller === 'macstore') {
    console.log('🚀 ~ htmlReplaceContract ~ data', data)

    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fechaHora = moment().locale('es').format('DD/MM/YYYY hh:mm')
    const marca: string = data.brand
    const modelo: string = data.model
    const capacity: string = data.capacity
    const serie: string = data.imei
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const barCodeText1 = '(1)OC2712' + String(moment().locale('es').format('YYYYMMDD') + data.discount_amount + '996')
    const barCodeText2 = '(2)987654321'
    const barCodeText3 = '(3)' + String(data.imei)
    const barCodeText4 = '(4)123456789'

    html = html
      .replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fechaHora}}', fechaHora)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{capacity}}', capacity)
      .replaceAll('{{serie}}', serie)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{barCode1}}', String(generateBarcodeBase64(barCodeText1)))
      .replaceAll('{{barCode2}}', String(generateBarcodeBase64(barCodeText2)))
      .replaceAll('{{barCode3}}', String(generateBarcodeBase64(barCodeText3)))
      .replaceAll('{{barCode4}}', String(generateBarcodeBase64(barCodeText4)))
  }

  if (data.seller === 'samsung') {
    console.log('🚀 ~ htmlReplaceContract ~ data', data)

    const fullname = String(data.name) + ' ' + String(data.lastname)
    const direccion = String(data.address) + ', ' + String(data.city)
    const movil = String(data.movil)

    html = html
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{direccion}}', direccion)
      .replaceAll('{{movil}}', movil)
      .replaceAll('{{email}}', data.email)
      .replaceAll('{{DNI}}', data.rut)
  }

  return html
}
