import NUIAlert from '@/components/Alert/Alert'
import { IConfigSeller } from '@/models/configSeller'
import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { currencyFormat } from '@/utils/currencyFormat'
import { Typography } from '@mui/material'
import { Button, cn, Switch } from '@nextui-org/react'
import { fromImage } from 'imtool'
import { enqueueSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTradeinContext } from '../hooks/useTradeinContext'

const Discounts = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const [discounts, setDiscounts] = useState<{ haveCharger: boolean, isBatteryHealthy: boolean }>({ haveCharger: true, isBatteryHealthy: true })
  const [data, setData] = useState<{ loading: boolean, imagen: string }>({ loading: false, imagen: '' })
  const { slug, id } = useParams()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    saveCtx({
      ...ctx,
      files: [...validFiles]
    })
  }, [ctx, saveCtx])

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1
  })

  const removeFile = (file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    saveCtx({
      ...ctx,
      files: ctx.files.filter(f => f !== file)
    })
  }

  const uploadImage = async () => {
    for (const element of ctx.files) {
      if (element.nameFile === undefined) {
        console.log('entro', ctx)
        delete element.preview
        element.blob = await fromImage(element).then((tool: any) => tool.thumbnail(800, false).toBlob())
        element.base64 = await blobToBase64(element.blob)
        delete element.blob
      }
    }
    console.log('🚀 ~ forawait ~ ctx.files:', ctx.files)
    setData({ ...data, loading: true })

    let _discount = ctx.tradein.new_discount_amount !== null ? ctx.tradein.new_discount_amount : ctx.tradein.discount_amount
    console.log('🚀 ~ uploadImage ~ _discount:', _discount)
    if (!discounts.haveCharger) {
      _discount = _discount - Number(import.meta.env.VITE_CHARGER_DISCOUNT)
      _discount = _discount < 0 ? 0 : _discount
      console.log('🚀 ~ :', _discount, Number(import.meta.env.VITE_CHARGER_DISCOUNT))
    }
    if (!discounts.isBatteryHealthy) {
      _discount = _discount - Number(import.meta.env.VITE_BATTERY_DISCOUNT)
      _discount = _discount < 0 ? 0 : _discount
      console.log('🚀 ~ :', _discount, Number(import.meta.env.VITE_BATTERY_DISCOUNT))
    }

    webApiService.saveFiles({ id, store_user: String(userState.id), files: ctx.files, seller: slug, battery: true, isBatteryHealthy: Number(discounts.isBatteryHealthy), discount: _discount, haveCharger: discounts.haveCharger }).then(() => {
      enqueueSnackbar(ctx.files.length > 0 ? 'Imagen cargada con exito' : 'Información cargada con exito', { variant: 'success' })
      saveCtx({ ...ctx, files: [], reload: true })
      setData({ ...data, loading: false })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getFiles(String(id)).then((res: any) => {
      setData({ loading: false, imagen: res.data.find((item: any) => item.name.includes('batteryHealth')).name })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const getMonto : (monto: number) => number = (monto) => {
    let _discount = monto
    if (!discounts.haveCharger || ctx.tradein.haveCharger === 0) {
      _discount = _discount + Number(import.meta.env.VITE_CHARGER_DISCOUNT)
    }
    if (!discounts.isBatteryHealthy || ctx.tradein.isBatteryHealthy === 0) {
      _discount = _discount + Number(import.meta.env.VITE_BATTERY_DISCOUNT)
    }
    return _discount
  }

  useEffect(() => {
    if (ctx.tradein.isBatteryHealthy !== null && ctx.tradein.sbu !== 'Smartwatch') loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.tradein.isBatteryHealthy])

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      {
      ctx.tradein.id_status === 1 || (isSamsungPeru && ctx.formAnswers.length === 0)
        ? (
          <div>
            <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
              Descuento
            </Typography>
            <NUIAlert type='info'>Se requiere el resultado de la evaluación técnica.</NUIAlert>
          </div>
          )
        : (
          <div>
            <div className='grid grid-cols-2 border-b-1 mb-2 '>
              <div><h6 className=' font-bold text-xl pb-2'>Descuentos</h6></div>
              <div className='text-end font-black text-lg'>Monto {ctx.tradein.haveCharger === null ? 'a descontar' : 'descontado'}: <span className='text-primary'>{currencyFormat(getMonto(0))}</span></div>
            </div>
            {
        ctx.tradein.haveCharger === null && (ctx.tradein.sbu === 'Notebook' || ctx.tradein.sbu === 'Smartwatch')
          ? (
            <Switch
              isSelected={!discounts.haveCharger} onValueChange={(value) => setDiscounts({ ...discounts, haveCharger: !value })}
              classNames={{
                base: cn(
                  'inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center',
                  'justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent',
                  'data-[selected=true]:border-primary'
                ),
                wrapper: 'p-0 h-4 overflow-visible',
                thumb: cn('w-6 h-6 border-2 shadow-lg',
                  'group-data-[hover=true]:border-primary',
                  // selected
                  'group-data-[selected=true]:ml-6',
                  // pressed
                  'group-data-[pressed=true]:w-7',
                  'group-data-[selected]:group-data-[pressed]:ml-4'
                )
              }}
            >
              <div className='flex flex-col gap-1'>
                <p className='text-medium'>¿Se entrega dispositivo sin cargador?</p>
              </div>
            </Switch>
            )
          : null
      }
            <span />
            {
        ctx.tradein.isBatteryHealthy === null && ctx.tradein.sbu !== 'Smartwatch'
          ? (
            <div className='py-2'>
              <Switch
                isDisabled={configSeller.config.permisos.ENABLE_CHECK_BATTERY === 0}
                isSelected={!discounts.isBatteryHealthy} onValueChange={(value) => setDiscounts({ ...discounts, isBatteryHealthy: !value })}
                classNames={{
                  base: cn(
                    'inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center',
                    'justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent',
                    'data-[selected=true]:border-primary'
                  ),
                  wrapper: 'p-0 h-4 overflow-visible',
                  thumb: cn('w-6 h-6 border-2 shadow-lg',
                    'group-data-[hover=true]:border-primary',
                    // selected
                    'group-data-[selected=true]:ml-6',
                    // pressed
                    'group-data-[pressed=true]:w-7',
                    'group-data-[selected]:group-data-[pressed]:ml-4'
                  )
                }}
              >
                <div className='flex flex-col gap-1'>
                  <p className='text-medium'>¿La capacidad de carga del dispositivo es menor al 80%?</p>
                </div>
              </Switch>
              <div className='pt-2'>
                <NUIAlert type='info'>Para avanzar debe subir foto de la evidencia del estado de capacidad máxima de carga de la batería</NUIAlert>
                <div
                  {...getRootProps()} style={{
                    border: ctx.files.length > 0 ? '1px solid #ccc' : '1px dashed #ff8181',
                    borderRadius: '5px',
                    padding: '20px',
                    textAlign: 'center',
                    marginTop: '6px',
                    cursor: 'pointer',
                    backgroundColor: isDragActive ? '#cce5ff' : ctx.files.length > 0 ? '#fff' : '#ffefef',
                    color: isDragActive ? '#ffffff' : '#000000'
                  }}
                >
                  <input {...getInputProps()} />
                  {
                isDragActive
                  ? (
                    <p>Arrastra y suelta las imágenes aquí...</p>
                    )
                  : (
                    <div hidden={ctx.files.length > 0}>
                      <h4 className='font-extrabold'>Adjuntar imagen capacidad de carga del dispositivo (Requerido)</h4>
                      <p>Arrastra y suelta la imagen aquí, o haz clic para seleccionar archivos</p>
                    </div>
                    )
              }
                  <p color='textSecondary' hidden={ctx.files.length > 0}>
                    (Solo se permiten archivos .png, .jpg y .jpeg)
                  </p>
                  <div className='grid grid-cols-1'>
                    {ctx.files.map((file, index) => (
                      <div key={index} className='max-w-44'>
                        <img src={URL.createObjectURL(file)} alt={`preview ${index}`} />
                        <p>{file.name}</p>
                        <Button color='danger' size='sm' onClick={(event: any) => removeFile(file, event)}>
                          Eliminar
                        </Button>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
            )
          : null
      }

            {
        // Si no se ha cargado el detalle de los descuentos, no se muestra el boton
        ctx.tradein.haveCharger === null
          ? (
            <div className='mt-2'>
              <Button isLoading={data.loading} isDisabled={ctx.files.length === 0 && ctx.tradein.sbu !== 'Smartwatch'} color='primary' onClick={uploadImage}>
                {ctx.tradein.sbu === 'Smartwatch' ? 'Enviar' : 'Cargar imagen'}
              </Button>
            </div>
            )
          : null
      }

            {
        ctx.tradein.haveCharger !== null && ctx.tradein.sbu !== 'Smartphone'
          ? (
            <div className='p-4 bg-slate-100 border-1 border-slate-500 rounded-lg mb-2'>
              <p className='text-large font-bold'> {ctx.tradein.haveCharger === 1 ? 'Dispositivo se entrega con cargador' : 'Dispositivo se entrega sin cargador'} </p>
            </div>
            )
          : null
      }
            {
        ctx.tradein.isBatteryHealthy !== null && ctx.tradein.sbu !== 'Smartwatch'
          ? (
            <div className='p-4 bg-slate-100 border-1 border-slate-500 rounded-lg'>
              <p className='text-large pb-4 font-bold'> {ctx.tradein.isBatteryHealthy === 1 ? 'Mayor ó igual al 80%' : 'No supera el 80%'} </p>
              <img
                className={isSamsungPeru ? 'h-[400px]' : 'w-full'}
                src={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/${configSeller.config.s3.FOLDER_APP}/imagenes/${id}/${data.imagen}`} alt={ctx.tradein.isBatteryHealthy === 1 ? 'Mayor ó igual al 80%' : 'No supera el 80%'}
              />
            </div>
            )
          : null
      }

          </div>)
      }

    </div>
  )
}

export default Discounts
