import React, { FC, useRef } from 'react'
import FileUpload from '@/assets/svg/FileUpload.svg?react'
import * as XLSX from 'xlsx'
import { Card } from '@nextui-org/react'
import { rowForTable, UploadExcelProps } from '@/models/uploadPrices'
import { enqueueSnackbar } from 'notistack'

const UploadPrices: FC<UploadExcelProps> = ({ state, setState }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setState({ ...state, isLoading: true })
      const reader = new FileReader()
      reader.onload = (e) => {
        const excelData = e.target?.result
        if (excelData) {
          const workbook = XLSX.read(excelData, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const jsonData: Array<{
            Marca: string;
            Modelo: string;
            Cpu: string;
            'Cpu generacion': string;
            Pantalla: string;
            Ram: string;
            Capacidad: string;
            Gpu: string;
            Descuento: number
           }> = XLSX.utils.sheet_to_json(sheet)

          const rows: rowForTable[] = jsonData.map((row, index) => ({
            key: index,
            brand: row.Marca,
            model: row.Modelo ? row.Modelo : '',
            cpu: row.Cpu ? row.Cpu : '',
            cpuGeneration: row['Cpu generacion'] ? row['Cpu generacion'] : '',
            screen: row.Pantalla ? row.Pantalla : '',
            ram: row.Ram ? row.Ram : '',
            capacity: row.Capacidad ? row.Capacidad : '',
            gpu: row.Gpu ? row.Gpu : '',
            discount: row.Descuento
          }))
          setState({ ...state, products: rows, file, isLoading: false })
        }
        enqueueSnackbar(`Documento ${file.name} cargado correctamente.`, { variant: 'success' })
      }
      reader.readAsArrayBuffer(file)
    }

    // Limpia el valor del input de archivo
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <div className='flex justify-between items-center'>
      <Card>
        <div className='flex flex-row items-center justify-center gap-5 p-2 border-1 border-gray-300 rounded-lg w-full h-14 mx-auto'>
          <label
            htmlFor='file-upload'
            className='cursor-pointer flex items-center gap-3 text-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition'
          >
            <div className='h-7 w-7'><FileUpload /></div>
            <span className='text-lg font-medium whitespace-nowrap'>Subir archivo</span>
            <input
              id='file-upload'
              type='file'
              accept='.xlsx,.xls,.xlsm,.csv'
              ref={fileInputRef}
              className='hidden'
              onChange={handleFileUpload}
            />
          </label>
          {state.file
            ? (
              <p className='text-base text-gray-700 break-words w-full text-center'>{state.file.name}</p>)
            : (
              <p className='text-base text-gray-400'>Ningún archivo seleccionado</p>
              )}
        </div>
      </Card>
    </div>
  )
}

export default UploadPrices
