import React, { createContext, useState } from 'react'

interface IOwnProps {
  data: {
    sbu: { id_sbu: number, name: string }
    brand: { id_brand: number, discount: string, brand: string, value: string, label: string }
    model: { id_model: number, model: string, value: string, label: string, amount: number, capacity: number }
    cpu: { id: number, name: string, value: string, label: string }
    gpu: { id: number, name: string, value: string, label: string }
    generation: { id_cpu_generation: number, cpu_generation: string, value: string, label: string }
    screen: { id_screen: number, screen: string, value: string, label: string }
    ram: { id_ram: number, ram: string, value: string, label: string }
    capacity: { id_capacity: number, capacity: string, value: string, label: string, amount: number }
    answers: Array<{ id_question: number, id_answer: number, weigth: number, answer: string }>
    name: string
    lastname: string
    email: string
  }
  steps: { current: number, isValid: boolean }
  loading: boolean,
  validation: boolean
}

export type FormularioClienteContextType = {
  ctx: IOwnProps;
  saveCtx: (ctx: IOwnProps) => void;
};

export const initialStateFormularioClienteContext: IOwnProps = {
  data: {
    sbu: { id_sbu: 0, name: '' },
    brand: { id_brand: 0, discount: '', brand: '', value: '', label: '' },
    model: { id_model: 0, model: '', value: '', label: '', amount: 0, capacity: 0 },
    cpu: { id: 0, name: '', value: '', label: '' },
    gpu: { id: 0, name: '', value: '', label: '' },
    generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
    screen: { id_screen: 0, screen: '', value: '', label: '' },
    ram: { id_ram: 0, ram: '', value: '', label: '' },
    capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 },
    answers: [],
    name: '',
    lastname: '',
    email: ''
  },
  steps: { current: 0, isValid: false },
  loading: true,
  validation: false
}

export const FormularioClienteContext = createContext<FormularioClienteContextType | null>(null)

export const HomePageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ctx, setCtx] = useState<IOwnProps>(initialStateFormularioClienteContext)

  const saveCtx = (_ctx: IOwnProps) => {
    setCtx(_ctx)
  }

  return <FormularioClienteContext.Provider value={{ ctx, saveCtx }}>{children}</FormularioClienteContext.Provider>
}
