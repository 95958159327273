import { IUser } from '@/models/user.model'
import { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'

interface IOwnProps{
  roles: Array<number>
  children: ReactNode
  PublicValidationFragment: any
}

const RoleChildrenGuard: FC<IOwnProps> = ({ roles, children, PublicValidationFragment }) => {
  const userState = useSelector((store: { user: IUser }) => store.user)
  return roles.includes(userState.id_role) ? children : PublicValidationFragment
}

export default RoleChildrenGuard
