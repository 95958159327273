import { PublicRoutes } from '@/constants/routes'
import { webApiService } from '@/services'
import { setConfig } from '@/store/config'
import { useAppDispatch } from '@/store/store'
import { Progress } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'

const LoadSeller = () => {
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()
  const [data, setData] = useState({ config: null, loading: true })
  const loadConfigSeller = async () => {
    const configSeller = await webApiService.getConfigBySlug(String(slug)).then(res => res.data)
    dispatch(setConfig({ loading: false, config: configSeller }))
    setData({ loading: false, config: configSeller })
  }

  useEffect(() => {
    loadConfigSeller()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (data.loading) {
    return (
      <Progress
        color='default'
        size='sm'
        isIndeterminate
        aria-label='Loading...'
        className='w-full'
      />
    )
  }

  if (data.config === null && !data.loading) return <Navigate to={PublicRoutes.NOTFOUND} />

  return (
    <Outlet />
  )
}

export default LoadSeller
