import { IModalProps } from '@/models/ModalProps'
import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import EyeFilledIcon from '@/assets/svg/EyeFilledIcon.svg?react'
import EyeSlashFilledIcon from '@/assets/svg/EyeSlashFilledIcon.svg?react'
import { MdCheckCircle, MdCancel } from 'react-icons/md'

const ModalResetPassword: React.FC<IModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{ loading: boolean, newPass: string, confirmPassword: string, showPassword: boolean, showConfirmPassword: boolean }>({
    loading: false,
    newPass: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false
  })
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = (): void => {
    setModal({ ...modal, visible: false })
  }

  const handleChange = (e: any): void => {
    e.preventDefault()
  }

  const setSuccess = (): void => {
    if (modal.parametros.isSuccess === 'success') {
      enqueueSnackbar('Contraseña guardada exitosamente, por favor, ingrese con su nueva contraseña', { variant: 'success' })
    } else if (modal.parametros.isSuccess === 'error') {
      enqueueSnackbar('Tu contraseña debe tener 8 caracteres mínimo, debe incluir mayúscula, minúscula, número y caracter especial ', { variant: 'error' })
      handleClose()
    } else {
      enqueueSnackbar('Necesitas crear una nueva contraseña por primera vez', { variant: 'warning' })
    }
  }

  const handlePasswordChange = (value: string): void => {
    const newPassword = value

    setPasswordRequirements({
      minLength: newPassword.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasLowerCase: /[a-z]/.test(newPassword),
      hasNumber: /[0-9]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*()_+{}[\]:;<>,.?~-]/.test(newPassword)
    })

    setData({ ...data, newPass: newPassword })
  }

  const handleConfirmPasswordChange = (value: string): void => {
    setData({ ...data, confirmPassword: value })
  }

  const togglePasswordVisibility = (): void => {
    setData({ ...data, showPassword: !data.showPassword })
  }

  const toggleConfirmPasswordVisibility = (): void => {
    setData({ ...data, showConfirmPassword: !data.showConfirmPassword })
  }

  useEffect(() => {
    setSuccess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.parametros.isSuccess])

  return (
    <Modal backdrop='blur' isOpen={modal.visible} onClose={handleClose}>
      <ModalContent>
        <ModalHeader className='flex flex-col gap-1'>Nueva Contraseña</ModalHeader>
        <ModalBody>
          <div>
            <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Nueva Contraseña</label>
            <Input
              type={data.showPassword ? 'text' : 'password'}
              name='password'
              value={data.newPass}
              onValueChange={handlePasswordChange}
              endContent={
                <button className='focus:outline-none' type='button' onClick={togglePasswordVisibility} aria-label='toggle password visibility'>
                  {data.showPassword ? <EyeSlashFilledIcon /> : <EyeFilledIcon />}
                </button>
                }
            />
          </div>
          <div>
            <label htmlFor='confirmPassword' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Confirme nueva contraseña</label>
            <Input
              type={data.showConfirmPassword ? 'text' : 'password'}
              name='confirmPassword'
              value={data.confirmPassword}
              onValueChange={handleConfirmPasswordChange}
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              endContent={
                <button className='focus:outline-none' type='button' onClick={toggleConfirmPasswordVisibility} aria-label='toggle password visibility'>
                  {data.showConfirmPassword ? <EyeSlashFilledIcon /> : <EyeFilledIcon />}
                </button>
                }
            />
          </div>
          <div className='bg-primary-100 border-primary-500 border-1 rounded-xl p-3'>
            <h4 className='text-lg font-bold'>Tu contraseña debe incluir al menos:</h4>
            <div className='flex items-center'>
              {passwordRequirements.minLength ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
              <span>Mínimo 8 caracteres</span>
            </div>
            <div className='flex items-center'>
              {passwordRequirements.hasUpperCase ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
              <span>Una mayúscula</span>
            </div>
            <div className='flex items-center'>
              {passwordRequirements.hasLowerCase ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
              <span>Una minúscula</span>
            </div>
            <div className='flex items-center'>
              {passwordRequirements.hasNumber ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
              <span>Un número</span>
            </div>
            <div className='flex items-center'>
              {passwordRequirements.hasSpecialChar ? <MdCheckCircle className='me-1 text-green-500' /> : <MdCancel className='me-1 text-red-500' />}
              <span>Un caractér especial</span>
            </div>
          </div>
          <div className='col-12 text-center'>
            <Button
              type='submit'
              color='primary'
              fullWidth
              onClick={() => modal.parametros.cnp(data.newPass, data.confirmPassword)}
              isDisabled={!passwordRequirements.minLength || !passwordRequirements.hasUpperCase || !passwordRequirements.hasLowerCase || !passwordRequirements.hasNumber || !passwordRequirements.hasSpecialChar || data.newPass !== data.confirmPassword}
              className='mt-3 mb-2 pt-1.3 pb-1.3'
            >
              Reestrablecer contraseña
            </Button>
          </div>

          <div className='row gy-2'>
            <div className='col-12 my-3'>

              <div />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalResetPassword
