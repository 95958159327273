import { Box, FormControl, FormControlLabel, FormLabel, Modal, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { Button } from '@nextui-org/react'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useEffect, useState } from 'react'
import { MdInfo } from 'react-icons/md'
import { webApiService } from '../../../../services'
import { useTradeinContext } from '../hooks/useTradeinContext'

const Recalculate = () => {
  const [data, setData] = useState<{ loading: boolean, questionList: any[], answers: any[], open: boolean }>({ loading: false, questionList: [], answers: [], open: false })
  const { enqueueSnackbar } = useSnackbar()
  const { ctx, saveCtx } = useTradeinContext()

  const loadData = async () => {
    webApiService.getAllQuestions().then(res => {
      if (res.success) {
        setData({ ...data, questionList: res.data, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleChangeQuestion = (event: ChangeEvent<HTMLInputElement>, idPregunta: number) => {
    console.log('🚀 ~ handleChangeQuestion ~ arrResp:', data.questionList, (event.target as HTMLInputElement).value)

    const arr = [...data.answers]
    console.log('🚀 ~ handleChangeQuestion ~ arr:', arr)

    if (arr.some(e => e.id_question === idPregunta)) {
      const idx = arr.findIndex(e => e.id_question === idPregunta)
      console.log('🚀 ~ handleChangeQuestion ~ idx:', idx)
      arr.splice(idx, 1)
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    } else {
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    }
    console.log('Entro', data.answers.length, data.questionList.filter(q => q.id_parent_question === null).length)
    setData({ ...data, answers: arr })
  }

  const handleClose = () => {
    setData({ ...data, open: false, loading: false, answers: [] })
  }

  const handleRecalculate = async () => {
    setData({ ...data, loading: true })
    const sumaPeso = data.answers.reduce((valorAnterior, valorActual) => { return valorAnterior + valorActual.weigth }, 0)
    const recalculate = await webApiService.recalculate({ sumaPeso, id: ctx.tradein.id, marca: ctx.tradein.make, modelo: ctx.tradein.modelName, capacidad: parseInt(ctx.tradein.memory.split(' ')[0]) + ' ' + ctx.tradein.memory.split(' ')[1], capacityTradeIn: ctx.tradein.capacity })
    console.log('🚀 ~ Recalculate ~ newDeviceAmount:', recalculate)

    setData({ ...data, loading: false, answers: [], open: false })
    saveCtx({ ...ctx, reload: true })
  }

  useEffect(() => {
    loadData()
    return () => setData({ loading: false, questionList: [], answers: [], open: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Button
        variant='shadow'
        color='warning'
        size='sm'
        style={{ marginLeft: '5px' }}
        endContent={<MdInfo style={{ fontSize: '12px' }} />}
        onClick={() => { setData({ ...data, open: true }) }}
      >
        Recalcular
      </Button>
      <Modal open={data.open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 2,
              textAlign: 'center',
              maxHeight: '90vh',
              overflowY: 'auto'
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                width: '100%',
                padding: 2
              }}
            >
              Evalúa tu equipo
            </Typography>
            <Paper variant='outlined' sx={{ padding: 2 }} className='small'>
              {
                    data.questionList.filter((q :any) => q.id_parent_question === null).map((p:any, i: number) => {
                      return (
                        <div className='mb-2 p-2 rounded shadow' key={i}>
                          <FormControl className='text-start w-100'>
                            <FormLabel id='demo-row-radio-buttons-group-label' className='small'>{p.questions}</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              name={'radio-group-' + (i)}
                              onChange={(e) => handleChangeQuestion(e, p.id)}
                              value={data.answers.find(a => a.id_question === p.id)?.id_answer}
                            >
                              {
                                data.questionList.filter((a: any) => Number(a.id_parent_question) === p.id).map((a:any, n: number) => {
                                  return (<FormControlLabel key={n} value={a.id} control={<Radio size='small' />} label={a.questions} className='small' />)
                                })
                            }
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )
                    })
                }

            </Paper>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                padding: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                gap: 2
              }}
            >
              <Button
                color='danger'
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button isLoading={data.loading} color='primary' type='submit' isDisabled={data.questionList.filter((q :any) => q.id_parent_question === null).length !== data.answers.length} onClick={handleRecalculate}>
                Recalcular
              </Button>
            </Box>
          </Box>

        </Box>
      </Modal>
    </div>
  )
}

export default Recalculate
