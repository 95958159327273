import { useContext } from 'react'
import { TradeinContext, TradeinContextType } from '../context/TradeinContext'

export const useTradeinContext = (): TradeinContextType => {
  const context = useContext(TradeinContext)
  if (!context) {
    throw new Error('useTradeinContext must be used within a TradeinProvider')
  }
  return context
}
