import { Button, Input, Spinner } from '@nextui-org/react'
import { webApiService } from '@/services'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
interface Data {
  logo: string
  nameOwner: string
  rut: string
  bank: string
  account: string
  interbankAccount: string
  loading: boolean
  saving: boolean
}

const initialData = {
  logo: '',
  nameOwner: '',
  rut: '',
  bank: '',
  account: '',
  interbankAccount: '',
  loading: false,
  saving: false
}

const BankAccountPage = () => {
  const { slug, idContract } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState<Data>(initialData)
  const [errors, setErrors] = useState<Record<string, string | null>>({})

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  const validateFields = () => {
    const newErrors: Record<string, string | null> = {}
    const message = 'Este campo es obligatorio'
    if (!data.nameOwner) newErrors.nameOwner = message
    if (!data.rut) newErrors.rut = message
    if (!data.bank) newErrors.bank = message
    if (!data.account) newErrors.account = message
    if (!data.interbankAccount) newErrors.interbankAccount = message
    setErrors(newErrors)
    return Object.keys(newErrors).every((key) => newErrors[key] === null)
  }

  const handleSave = async () => {
    if (!validateFields()) return
    setData({ ...data, saving: true })
    try {
      const formData = { nameOwner: data.nameOwner, rut: data.rut, bank: data.bank, account: data.account, interbankAccount: data.interbankAccount, docusignId: idContract }
      console.log('formData => ', formData)
      const res = await webApiService.saveDataAccount(formData)
      console.log('res => ', res)
      setData({ ...initialData, logo: data.logo })
      enqueueSnackbar('Sus datos fueron enviados correctamente.', { variant: 'success' })
    } catch (error) {
      console.log('error => ', error)
      enqueueSnackbar('Hubo un error, por favor vuelva a intentar.', { variant: 'error' })
      setData({ ...data, saving: false })
    }
  }

  useEffect(() => {
    setData({ ...data, loading: true })
    const loadConfigSeller = async () => {
      const logo = await webApiService.getConfigBySlug(String(slug)).then(res => res.data.logo)
      setData({ ...data, logo, loading: false })
    }
    loadConfigSeller()
  }, []) //eslint-disable-line

  return (
    <>
      {data.loading
        ? (
          <div className='w-full flex justify-center h-[200px]'>
            <Spinner color='primary' label='Cargando...' size='lg' />
          </div>
          )
        : (
          <div className='w-full flex justify-center'>
            <div className='w-[500px] flex flex-col items-center'>
              <img src={data.logo} className='w-2/4' />
              <div className='bg-white rounded-lg shadow-lg p-4 w-full flex flex-col gap-4'>
                <p className='dataBankAccount'>Datos Bancarios</p>
                <div className='flex flex-col gap-4'>
                  <Input
                    type='text'
                    label='Nombre titular'
                    name='nameOwner'
                    value={data.nameOwner}
                    onChange={handleChange}
                    disabled={data.saving}
                    errorMessage={errors.nameOwner}
                    isInvalid={!!errors.nameOwner}
                  />
                  <Input
                    type='text'
                    label='DNI'
                    name='rut'
                    value={data.rut}
                    onChange={handleChange}
                    disabled={data.saving}
                    errorMessage={errors.rut}
                    isInvalid={!!errors.rut}
                  />
                  <Input
                    type='text'
                    label='Banco'
                    name='bank'
                    value={data.bank}
                    onChange={handleChange}
                    disabled={data.saving}
                    errorMessage={errors.bank}
                    isInvalid={!!errors.bank}
                  />
                  <Input
                    type='text'
                    label='Número de cuenta'
                    name='account'
                    value={data.account}
                    onChange={handleChange}
                    disabled={data.saving}
                    errorMessage={errors.account}
                    isInvalid={!!errors.account}
                  />
                  <Input
                    type='text'
                    label='Número de cuenta interbancaria'
                    name='interbankAccount'
                    value={data.interbankAccount}
                    onChange={handleChange}
                    disabled={data.saving}
                    errorMessage={errors.interbankAccount}
                    isInvalid={!!errors.interbankAccount}
                  />
                </div>
                <div className='flex justify-end'>
                  <Button
                    color='primary'
                    onClick={handleSave}
                    isLoading={data.saving}
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          )}
    </>

  )
}

export default BankAccountPage
