import { FC } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from '@nextui-org/react'
import { UploadExcelState, columnsForTable } from '@/models/uploadPrices'

const PricesTable: FC<UploadExcelState> = ({ state }) => {
  const selectedSbu = state.sbuForTable
  console.log('selectedSbu => ', selectedSbu)
  if (selectedSbu.key === '') return null
  const columns: any = columnsForTable.filter((column) => column.visible!.includes(Number(selectedSbu.key)))

  function getKeyValue<T, K extends keyof T> (obj: T, key: K): T[K] {
    return obj[key]
  }

  return (
    <Table
      isStriped isHeaderSticky aria-label='Tabla proveniente del Excel'
      classNames={{
        base: 'max-h-[520px]'
      }} style={{ tableLayout: 'fixed', width: '100%', maxHeight: '600px', overflowY: 'auto' }}
    >
      <TableHeader columns={columns}>
        {(column: any) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={state.products}>
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => <TableCell>{getKeyValue(item, columnKey as keyof typeof item)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default PricesTable
