import { IConfigSeller } from '@/models/configSeller'
import { clearLocalStorage, persistLocalStorage } from '@/utils/localStorage.util'
import { createSlice } from '@reduxjs/toolkit'

// import axios from 'axios'

export const EmptyConfigState : IConfigSeller = {
  loading: true,
  config: {
    s3: {
      FOLDER_APP: ''
    },
    html: '',
    logo: '',
    color: '',
    templates: {
      TEMPLATE_CODIGO: '',
      TEMPLATE_SINCODIGO: '',
      TEMPLATE_ACEPTADO: '',
      TEMPLATE_RECHAZADO: ''
    },
    permisos: {
      EJECUTIVO_ENABLE_FOLIO: 0,
      SBUS_VISIBLES: [],
      WEIGHT_REQUIRED: 0,
      ENABLE_DIR_CONTRACT: 0,
      ENABLE_EXCEL_COPPEL: 0,
      ENABLE_GIFCARD_FCOM: 0,
      ENABLE_CHECK_BATTERY: 0,
      ENABLE_DIR_STATE_CONTRACT: 0,
      ENABLE_CIVIL_STATE_CONTRACT: 0
    },
    terms: ''
  }
}

export const ConfigKey = 'config'

const configSlice = createSlice({
  name: 'auth',
  initialState: localStorage.getItem(ConfigKey) !== null ? JSON.parse(String(localStorage.getItem(ConfigKey))) : EmptyConfigState,
  reducers: {
    setConfig: (_state, action) => {
      persistLocalStorage(ConfigKey, action.payload)
      return action.payload
    },
    updateConfig: (state, action) => {
      const result = { ...state, ...action.payload }
      persistLocalStorage(ConfigKey, result)
      return result
    },
    resetconfig: () => {
      clearLocalStorage(ConfigKey)
      return EmptyConfigState
    }
  }
})

export const { setConfig, updateConfig, resetconfig } = configSlice.actions

export default configSlice.reducer
