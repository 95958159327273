import { webApiService } from '@/services'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTradeinContext } from '../hooks/useTradeinContext'
import SerieHelper from './SerieHelper'
import NUIAlert from '@/components/Alert/Alert'

const DeviceMobile: React.FC<any> = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const [loading, setLoading] = useState(false)

  const handleSerieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, serie: event.target.value })
  }

  const handleSerieSave = (serie: string) => {
    setLoading(true)
    webApiService.update({ stage: 7, id: ctx.tradein.id, serie })
      .then(() => {
        enqueueSnackbar('Se actualizó el número de IMEI/serie.', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el número de IMEI/serie.', { variant: 'error' })
        console.log('error ==>', error)
      }).finally(() => {
        saveCtx({ ...ctx, reload: true })
        setLoading(false)
      })
  }
  // console.log('ctx', ctx.tradein)
  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Dispositivo</h6>
      <div className='grid grid-cols-12'>
        <div className='col-span-12'>
          <p><strong>Tipo:</strong> {ctx.tradein.sbu}</p>
        </div>
        <div className='col-span-12'>
          <p><strong>Marca:</strong> {ctx.tradein.brand}</p>
        </div>
        <div className='col-span-12'>
          <p><strong>Modelo:</strong> {ctx.tradein.model}</p>
        </div>
        {ctx.tradein.sbu === 'Notebook' && (
          <>
            <div className='col-span-12'>
              <p><strong>CPU:</strong> {ctx.tradein.cpu}</p>
            </div>
            <div className='col-span-12'>
              <p><strong>Generación del CPU:</strong> {ctx.tradein.cpu_generation}</p>
            </div>
            <div className='col-span-12'>
              <p><strong>Pantalla:</strong> {ctx.tradein.screen}</p>
            </div>
            <div className='col-span-12'>
              <p><strong>Memoria RAM:</strong> {ctx.tradein.ram}</p>
            </div>
            <div className='col-span-12'>
              <p><strong>Almacenamiento:</strong> {ctx.tradein.capacity}</p>
            </div>
          </>
        )}
        <div className='col-span-12'>
          <p><strong>N° de serie: </strong></p>
        </div>
        <Grid item xs={12} className='col-span-12'>
          <Typography variant='body1'>
            {loading
              ? (
                <CircularProgress />
                )
              : (
                  ctx.tradein.id_status === 1 && ctx.tradein.serie === null
                    ? (
                      <Box position='relative' display='flex' alignItems='center' mt={2} mb={2}>
                        <Box position='relative' flexGrow={1} mr={2}>
                          <TextField
                            className='search-box'
                            label='Agregar serie'
                            variant='outlined'
                            size='small'
                            value={ctx.serie}
                            onChange={handleSerieChange}
                            style={{ width: '100%' }}
                          />
                          <SerieHelper style={{ position: 'absolute', top: 0, right: 0, transform: 'translate(50%, -50%)' }} />
                        </Box>
                        <Button
                          variant='contained'
                          size='small'
                          color='primary'
                          onClick={() => handleSerieSave(ctx.serie)}
                        >
                          Guardar
                        </Button>
                      </Box>
                      )
                    : (
                        ctx.tradein.serie
                      )
                )}
          </Typography>
          {ctx.tradein.id_status === 1 && ctx.tradein.serie === null && (
            <NUIAlert type='warning'>
              Debe ingresar el N° de Serie del dispositivo
            </NUIAlert>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default DeviceMobile
