import { UTCDateToCountryDate } from '@/utils/countryDate.util'
import { StatusColor } from '@/utils/StatusColor.utility'
import { Button, Pagination, SortDescriptor, Selection } from '@nextui-org/react'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import XLSX from 'xlsx-js-style'

const useTableData = (columns: any, excelName: string, list: any[]) => {
  const [page, setPage] = useState(0)
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'age',
    direction: 'ascending'
  })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterValue, setFilterValue] = useState('')
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]))
  type User = typeof list[0];
  const hasSearchFilter = Boolean(filterValue)

  const filteredItems = useMemo(() => {
    let filteredList = [...list]

    if (hasSearchFilter) {
      filteredList = filteredList.filter((item) => {
        return columns.some((column: any) =>
          String(item[column.uid]).toLowerCase().includes(filterValue.toLowerCase())
        )
      })
    }

    return filteredList
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, filterValue])

  const pages = Math.ceil(filteredItems.length / rowsPerPage)

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage
    const end = start + rowsPerPage

    return filteredItems.slice(start, end)
  }, [page, filteredItems, rowsPerPage])

  const sortedItems = useMemo(() => {
    return [...items].sort((a: User, b: User) => {
      const first = a[sortDescriptor.column as keyof User] as number
      const second = b[sortDescriptor.column as keyof User] as number
      const cmp = first < second ? -1 : first > second ? 1 : 0

      return sortDescriptor.direction === 'descending' ? -cmp : cmp
    })
  }, [sortDescriptor, items])

  const onNextPage = useCallback(() => {
    if (page < pages) {
      setPage(page + 1)
    }
  }, [page, pages])

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1)
    }
  }, [page])

  const onRowsPerPageChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value))
    setPage(1)
  }, [])

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value)
      setPage(1)
    } else {
      setFilterValue('')
    }
  }, [])

  const onClear = useCallback(() => {
    setFilterValue('')
    setPage(1)
  }, [])

  const bottomContent = useMemo(() => {
    return (
      <div className='py-2 px-2 flex justify-between items-center'>
        <Pagination
          isCompact
          showControls
          showShadow
          color='primary'
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className='hidden sm:flex w-[30%] justify-end gap-2'>
          <Button isDisabled={pages === 1} size='sm' variant='flat' onPress={onPreviousPage}>
            Previous
          </Button>
          <Button isDisabled={pages === 1} size='sm' variant='flat' onPress={onNextPage}>
            Next
          </Button>
        </div>
        <div className='flex justify-between items-center'>
          <span className='text-default-400 text-small mr-3'>Total: {sortedItems.length} registros</span>
          <label className='flex items-center text-default-400 text-small'>
            Filas por página:
            <select
              className='bg-transparent outline-none text-default-400 text-small'
              onChange={onRowsPerPageChange}
              value={rowsPerPage}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </label>
        </div>
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, page, pages, hasSearchFilter])

  const exportToCSV = () => {
    const isPrincipal = excelName.toLowerCase().includes('principal')
    if (isPrincipal && !columns.some((ele: any) => ele.uid === 'last_status_date')) {
      columns.push({ name: 'Fecha último estado', uid: 'last_status_date' })
    }
    const rows = filteredItems.map(item => {
      const row: any = {}
      columns.forEach((header: any) => {
        if (header.uid !== 'actions') {
          const isDate = header.uid.includes('fecha') || header.uid.includes('last_status_date')
          row[header.name] = isDate ? UTCDateToCountryDate(item[header.uid]) : item[header.uid]
        }
      })
      return row
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(rows)

    // Apply background color to 'status' column
    const statusColumnIndex = columns.findIndex((col: any) => col.uid === 'status') // +1 because Excel columns are 1-based
    if (statusColumnIndex > 0) {
      const range = XLSX.utils.decode_range(String(worksheet['!ref']))
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) { // Skip header row
        const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: statusColumnIndex })
        const cellValue = worksheet[cellAddress] ? worksheet[cellAddress].v : null
        if (!worksheet[cellAddress]) continue
        if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {}

        let bgColor = 'ffffff'
        bgColor = StatusColor(cellValue.toUpperCase())
        worksheet[cellAddress].s = {
          fill: { fgColor: { rgb: bgColor.replace('#', '') } },
          font: { color: { rgb: 'ffffff' } }
        }
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, excelName)
    const fileName = excelName + '.xlsx'
    XLSX.writeFile(workbook, fileName)
  }

  return {
    selectedKeys,
    sortDescriptor,
    filterValue,
    sortedItems,
    bottomContent,
    onSearchChange,
    exportToCSV,
    onClear,
    setSortDescriptor,
    setPage,
    setSelectedKeys
  }
}

export default useTableData
