import AppleLogo from '@/assets/images/logo-apple.png'
import SamsungLogo from '@/assets/images/logo-samsung.png'
import GarminLogo from '@/assets/images/logo-garmin.png'
import HpLogo from '@/assets/images/logo-hp.png'
import DellLogo from '@/assets/images/logo-dell.png'
import LenovoLogo from '@/assets/images/logo-lenovo.png'
import HuaweiLogo from '@/assets/images/logo-huawei.png'
import MotorolaLogo from '@/assets/images/logo-motorola.png'
import VivoLogo from '@/assets/images/logo-vivo.png'
import XiaomiLogo from '@/assets/images/logo-xiaomi.png'
import OppoLogo from '@/assets/images/logo-oppo.png'
import HonorLogo from '@/assets/images/logo-honor.png'

export const brandLogo = (brand: string) => {
  switch (brand.toLowerCase()) {
    case 'apple':
      return AppleLogo
    case 'samsung':
      return SamsungLogo
    case 'garmin':
      return GarminLogo
    case 'hp':
      return HpLogo
    case 'dell':
      return DellLogo
    case 'lenovo':
      return LenovoLogo
    case 'huawei':
      return HuaweiLogo
    case 'motorola':
      return MotorolaLogo
    case 'vivo':
      return VivoLogo
    case 'xiaomi':
      return XiaomiLogo
    case 'oppo':
      return OppoLogo
    case 'honor':
      return HonorLogo
    default:
      return ''
  }
}
