import * as React from "react";
const SvgFileUpload = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#FAA85F"
}, cx: 376, cy: 400, r: 112 }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "376,336 328,400 360,400 360,464 392,464 392,400 424,400 " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#00384E"
}, d: "M256.352,480H56V32h192v128h128v96c11.008,0,21.696,1.36,32,3.712V137.376L270.624,0H24v512h261.696 C274.384,502.864,264.464,492.096,256.352,480z M280,54.624L353.376,128H280V54.624z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#72C6EF"
}, d: "M232,400c0-68.384,47.968-125.68,112-140.288V160h-96V64H88v384h152.4 C235.056,432.96,232,416.848,232,400z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 136, y: 240, style: {
  fill: "#00384D"
}, width: 160, height: 32 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#00384D"
}, d: "M268.976,304H136v32h111.2C253.008,324.336,260.352,313.6,268.976,304z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#00384D"
}, d: "M136,368v32h96c0-11.008,1.36-21.696,3.712-32H136z" })));
export default SvgFileUpload;
