import { MdAssignmentTurnedIn, MdCancel, MdCheckCircle, MdHourglassBottom, MdInfo, MdRequestQuote, MdThumbDown, MdTour, MdVerifiedUser } from 'react-icons/md'

export const Icon = (status: string) => {
  switch (status) {
    case 'COTIZADO':
      return (<MdRequestQuote />)
    case 'APROBADO':
      return <MdCheckCircle />
    case 'NO APROBADO':
      return <MdCancel />
    case 'VALIDADO':
      return <MdVerifiedUser />
    case 'FIRMADO':
      return <MdAssignmentTurnedIn />
    case 'ENTREGADO':
      return <MdHourglassBottom />
    case 'RETIRADO REUSE':
      return <MdTour />
    case 'RECHAZADO':
      return <MdThumbDown />
    default:
      return <MdInfo />
  }
}
