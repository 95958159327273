export const currencyFormat: (monto: number, currency?: 'CL' | 'PE' | 'MX') => string = (monto, currency = 'CL') => {
  const currencyData = { CL: { format: 'es-CL', currency: 'CLP' }, PE: { format: 'es-PE', currency: 'PEN' }, MX: { format: 'es-MX', currency: 'MEX' } }

  const format = currencyData[currency].format
  if (monto === 0) {
    return '0'
  } else {
    return new Intl.NumberFormat(`${format}`, {
      style: 'currency',
      currency: currencyData[currency].currency,
      minimumFractionDigits: 0
    }).format(monto)
  }
}
