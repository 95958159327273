import { UploadExcelProps } from '@/models/uploadPrices'
import { Select, SelectItem } from '@nextui-org/react'
import { FC } from 'react'

const SelectSbu: FC<UploadExcelProps> = ({ state, setState }) => {
  const handleSbuChange = (selectedKey: any) => {
    const selectedSbu = state.allSbus.find((sbu) => sbu.key === selectedKey)
    console.log('selectedKey =>', selectedKey)

    if (selectedSbu) {
      console.log('selectedSbu => ', selectedSbu)
      setState(prevState => ({ ...prevState, products: [], sbuForTable: selectedSbu, file: null }))
    }
  }

  return (
    <Select
      label='Sbu'
      placeholder='Seleccionar el sbu'
      className='max-w-xs'
      color='success'
      onChange={(e) => handleSbuChange(e.target.value)}
    >
      {state.allSbus.map((item) => (
        <SelectItem key={item.key}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  )
}

export default SelectSbu
