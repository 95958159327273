import { webApiService } from '@/services'
import { Button } from '@nextui-org/react'
import { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import XLSX from 'xlsx-js-style'

const BulkSamsungPage = () => {
  const [data, setData] = useState<{ file: File | null, loading:boolean, list: any[], html: string}>({ file: null, loading: false, list: [], html: '' })
  const { slug } = useParams<{ slug: string }>()
  const accept: Accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    const reader = new FileReader()
    reader.onload = (e) => {
      const _data = e.target?.result
      const workbook = XLSX.read(_data, { type: 'binary' })
      console.log(workbook.SheetNames)
      const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

      setData({ ...data, list: rowObject })
      console.log('🚀 ~ readExcel ~ rowObject', rowObject)
    }
    // file to blob type
    reader.readAsBinaryString(validFiles[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  })

  const cargarTradein = async () => {
    setData({ ...data, loading: true })
    let html = '----- INICIO -----<br/>'
    for (const item of data.list) {
      const objTradeIn = {
        rut: item['DNI / RUC'],
        fecha_entrega: item['Delivery Date'],
        distrito: item.District,
        email: item.Email,
        fecha: item.Fecha,
        name: item['First Name'],
        lastname: item['Last Name'],
        direccion: item['Payment Address'],
        telefono: item.Phone,
        poId: item['Po Id'],
        producto: item['Product Name'],
        sku: item.Sku,
        dispositivo: item['Trade_in_Return Product'],
        seller: slug
      }
      console.log('🚀 ~ sendMails ~ objTradeIn:', objTradeIn)

      await webApiService.saveFromBulk(objTradeIn).then((res) => {
        console.log('🚀 ~ sendMails ~ res', res)
        html += '<p>' + item['Po Id'] + ' - ' + res.data + '</p>'
      }).catch((err) => {
        console.log('🚀 ~ awaitwebApiService.sendMails ~ err:', err)
        html += '<p>' + item['Po Id'] + ' - ' + err.response.data.message + '</p>'
      })
      setData({ ...data, html, loading: true })
    }
    html += '----- FIN -----<br/>'
    setData({ ...data, loading: false, html })
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Carga Masiva Tradein de Samsung</h6>
      <div className='py-4'>
        <p className='text-sm'>Este módulo permite cargar masivamente tradein generados en la pagina de samsung y cargarlos en excel (.xlsx)</p>
      </div>
      {
        data.list.length > 0
          ? (
            <div>
              <div className='p-6 text-center'>
                <p className='text-xl'>El excel contiene <strong>{data.list.length}</strong> registros registros de tradein  </p>
                <div className='mt-4'>
                  <Button size='lg' variant='shadow' color='secondary' isLoading={data.loading} onClick={cargarTradein}>Comenzar importación</Button>
                </div>
              </div>
              <div className='bg-black p-2 text-white' dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
            )
          : (
            <div
              {...getRootProps()} style={{
                border: '2px dashed #cccccc',
                borderRadius: '5px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                color: isDragActive ? '#ffffff' : '#000000'
              }}
            >
              <input {...getInputProps()} />
              {
                    isDragActive
                      ? (
                        <p>Arrastre el archivo .XLSX y suelta las aquí...</p>
                        )
                      : (
                        <p>Arrastre y suelte el archivo aquí, o haz clic para seleccionar el archivo</p>
                        )
                  }
              <p>
                (Solo se permite archivo .xlsx)
              </p>
            </div>
            )
      }
    </div>
  )
}

export default BulkSamsungPage
