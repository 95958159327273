import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IAppStore } from '../../../../models/store.model'
import { webApiService } from '../../../../services'
import { useTradeinContext } from '../hooks/useTradeinContext'

const ResendCode = () => {
  const [data, setData] = useState<{ open: boolean, respuesta: string, done: boolean; login_code: string; new_id_tradein: string; loading: boolean}>({ open: false, respuesta: '', done: false, login_code: '', new_id_tradein: '', loading: false })
  const { id } = useParams()
  const { ctx, saveCtx } = useTradeinContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const { slug } = useParams()
  const handleClose = () => {
    setData({ open: false, respuesta: '', done: false, login_code: '', new_id_tradein: '', loading: false })
  }

  const handleResendCode = () => {
    setData({ ...data, loading: true })
    const objPhonecheck = {
      id_tradein: id,
      email: data.respuesta,
      name: ctx.tradein.name,
      lastname: ctx.tradein.lastname,
      id_user: userState.id,
      seller: slug
    }
    webApiService.resendCode(objPhonecheck)
      .then((response) => {
        enqueueSnackbar('Se ha enviado el nuevo código al email:' + data.respuesta, { variant: 'success' })
        console.log('data handleResendCode ==>', response.data)
        setData({ ...data, loading: false, login_code: response.data.login_code, done: true, new_id_tradein: response.data.new_id_tradein })
        saveCtx({ ...ctx, reload: true })
      }).catch((error) => {
        enqueueSnackbar('Hubo un error al reenviar el código', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        setData({ ...data, loading: false })
        console.log('error ==>', error)
      })
  }

  const handleContinue = () => {
    console.log('data.new_id_tradein ==>', String(data.new_id_tradein))
    setData({ open: false, respuesta: '', done: false, login_code: '', new_id_tradein: '', loading: false })
    location.href = '/' + slug + '/backoffice/trade-in/' + String(data.new_id_tradein)
    // navigate(', { replace: true })
  }

  return (
    <div>
      <Button
        variant='shadow'
        color='warning'
        size='sm'
        style={{ marginLeft: '5px' }}
        className='text-white'
        onClick={() => { setData({ ...data, open: true }) }}
      >
        Reenviar código
      </Button>

      <Modal isOpen={data.open} onClose={handleClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Reenviar código para autoevaluación</ModalHeader>
              <ModalBody>
                {
                    data.done
                      ? (
                        <div className='text-center text-small'>
                          <h3>Hemos generado el codigo: <strong>{data.login_code}</strong></h3>
                          <p>Pincha en <strong>"Continuar</strong> para ir al nuevo Tradein.</p>
                        </div>
                        )
                      : (
                        <div className='text-small'>
                          <h3 className='mb-4'>Enviaremos un nuevo código para volver a realizar la evaluación del dispositivo.</h3>
                          <RadioGroup
                            name='email_option'
                            size='sm'
                            value={data.respuesta}
                            onChange={(e) => setData({ ...data, respuesta: e.target.value })}
                          >
                            <Radio value={ctx.tradein.email}>{`Email cliente (${ctx.tradein.email})`}</Radio>
                            <Radio value={userState.email}>{`Módulo (${userState.email})`}</Radio>
                          </RadioGroup>
                        </div>
                        )
                }
              </ModalBody>
              <ModalFooter>
                <Button
                  size='sm'
                  color='danger'
                  onClick={onClose}
                  disabled={data.done}
                >
                  Cerrar
                </Button>
                {
                data.done
                  ? (
                    <Button color='primary' size='sm' type='submit' disabled={data.respuesta === ''} onClick={handleContinue} isLoading={data.loading}>
                      Continuar
                    </Button>
                    )
                  : (
                    <Button color='primary' size='sm' type='submit' disabled={data.respuesta === '' || data.done} onClick={handleResendCode} isLoading={data.loading} isDisabled={data.respuesta === ''}>
                      Enviar
                    </Button>
                    )
              }
              </ModalFooter>
            </>
          )}

        </ModalContent>
      </Modal>
    </div>
  )
}

export default ResendCode
