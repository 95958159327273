import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import store from './store/store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
)
