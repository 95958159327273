import { webApiService } from '@/services'
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { MdLockPerson } from 'react-icons/md'

interface IOwnProps {
    isOpen: boolean
    onClose: () => void
    folio: string
    id: number
    data: { loading: boolean, list: any[], edit: { value: string, id: number, active: boolean } }
    setData: React.Dispatch<React.SetStateAction<any>>
}

const ModalFolio: React.FC<IOwnProps> = ({ onClose, isOpen, folio, id, setData, data }) => {
  const onEnter = () => {
    setData({ ...data, loading: true })
    webApiService.updateOnlyFolio({ id, folio: data.edit.value }).then((res) => {
      console.log('🚀 ~ webApiService.updateOnlyFolio ~ res:', res)
      const newList = data.list.map((item: any) => {
        if (item.id === id) {
          return { ...item, folio: data.edit.value }
        }
        return item
      })
      console.log('🚀 ~ newList ~ newList:', newList)

      setData({ ...data, loading: false, edit: { id: 0, value: '', active: false, indice: 0 }, list: [...newList] })
    }).catch((error) => {
      if (error.response !== undefined && error.response.data !== undefined && error.response.data.message.includes('Duplicate entry') >= 0) {
        enqueueSnackbar('El folio ingresado ya existe', { variant: 'error' })
      } else if (error.response !== undefined && error.response.data !== undefined) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
      console.log('Error loading data:', error)
      setData({ ...data, loading: false, edit: { id: 0, value: '', active: false, indice: 0 } })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      placement='top-center'
      backdrop='blur'
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>Folio para tradein # {id}</ModalHeader>
            <ModalBody>
              <Input
                autoFocus
                value={folio}
                endContent={
                  <MdLockPerson className='text-2xl text-default-400 pointer-events-none flex-shrink-0' />
                  }
                label='Folio'
                onChange={(e) => setData({ ...data, edit: { ...data.edit, value: e.target.value } })}
                placeholder='Ingrese folio'
                variant='bordered'
              />
            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='flat' onPress={onClose}>
                Cancelar
              </Button>
              <Button color='primary' onPress={onEnter} isLoading={data.loading}>
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalFolio
