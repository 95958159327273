// import { FindInPage as FindInPageIcon } from '@mui/icons-material'
import { Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IAppStore } from '../../../../models/store.model'
import { StyledTableCell, StyledTableRow } from '../../../../models/tableList'
import { webApiService } from '../../../../services'
// Import the styles
import { useTradeinContext } from '../hooks/useTradeinContext'
import NUIAlert from '@/components/Alert/Alert'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80vh',
  minHeight: '500px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const Certificate = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = useState<{ loading: boolean, openPdf: boolean }>({ loading: false, openPdf: false })
  const { ctx, saveCtx } = useTradeinContext()

  // const handleOpen = (action: string, certificate: string) => {
  //   saveCtx({ ...ctx, actionType: action, certificateType: certificate, openModal: true })
  // }

  const handleClose = () => {
    saveCtx({ ...ctx, openModal: false })
  }

  const handleConfirmCertificate = () => {
    setData({ ...data, loading: true })
    if (ctx.certificateType === 'theft') {
      saveCtx({ ...ctx, certificateTheft: ctx.actionType, openModal: false })
    } else if (ctx.certificateType === 'possession') {
      saveCtx({ ...ctx, certificatePossession: ctx.actionType, openModal: false })
    }
    webApiService.update({
      stage: 3,
      id: ctx.tradein.id,
      certificate_type: ctx.certificateType,
      certificate_value: ctx.actionType,
      user_id: userState.id
    })
      .then(() => {
        enqueueSnackbar('Registro actualizado', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el registro.', { variant: 'error' })
        console.log('error ==>', error)
      }).finally(() => {
        saveCtx({ ...ctx, openModal: false, reload: true })
        setData({ ...data, loading: false })
      })
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Certificado de Robo</h6>
      {data.loading
        ? <CircularProgress />
        : (
            ctx.tradein.id_status >= 2 && ctx.tradein.serie !== null
              ? (
                <>
                  <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          {/* <StyledTableCell align='left'>Nombre</StyledTableCell> */}
                          <StyledTableCell align='left'>Resultado</StyledTableCell>
                          <StyledTableCell align='right'>Estado</StyledTableCell>
                          {/* <StyledTableCell /> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow
                          key={`${ctx.tradein.id}-theft`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {/* <StyledTableCell align='left'>Robo</StyledTableCell> */}
                          <StyledTableCell align='left'>
                            {/* <Tooltip title='Ver certificado'>
                                <Button variant='contained' size='small' startIcon={<FindInPageIcon />} onClick={() => { setData({ ...data, openPdf: true }) }} />
                              </Tooltip> */}
                            <Typography variant='body2' component='div' style={{ color: ctx.tradein.certificate_theft === 'VALID' ? 'green' : ctx.tradein.certificate_theft === 'INVALID' ? 'red' : '#000000de' }}>
                              {ctx.tradein.certificate_theft === 'VALID' ? 'Equipo no ha sido reportado como perdido o robado.' : ctx.tradein.certificate_theft === 'INVALID' ? 'Equipo esta reportado como perdido o robado.' : 'Esperando el resultado de la evaluación...'}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align='right'>
                            <Badge
                              badgeContent={
                            ctx.tradein.certificate_theft === 'VALID'
                              ? 'Válido'
                              : ctx.tradein.certificate_theft === 'INVALID'
                                ? 'Inválido'
                                : 'Sin validar'
                        }
                              sx={{
                                '& .MuiBadge-badge': {
                                  backgroundColor:
                              ctx.tradein.certificate_theft === 'VALID'
                                ? '#2e7d32'
                                : ctx.tradein.certificate_theft === 'INVALID'
                                  ? '#f44336'
                                  : '#ff9800',
                                  color: '#fff',
                                  position: 'static',
                                  transform: 'none'
                                }
                              }}
                            />
                          </StyledTableCell>
                          {/* <StyledTableCell align='right'>
                              {ctx.tradein.certificate_theft === null
                                ? (
                                  <Box display='flex' justifyContent='flex-end' gap={1}>
                                    <Tooltip title='Marcar como Inválido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='warning'
                                        startIcon={<WarningIcon />}
                                        onClick={() => handleOpen('INVALID', 'theft')}
                                        disabled={ctx.tradein.certificate_theft === 'INVALID' || ctx.tradein.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                    <Tooltip title='Marcar como Válido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='success'
                                        endIcon={<DoneIcon />}
                                        onClick={() => handleOpen('VALID', 'theft')}
                                        disabled={ctx.tradein.certificate_theft === 'INVALID' || ctx.tradein.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                  </Box>
                                  )
                                : (
                                    ''
                                  )}
                            </StyledTableCell> */}
                        </StyledTableRow>
                        {/* <StyledTableRow
                            key={`${ctx.tradein.id}-possession`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <StyledTableCell align='left'>Tenencia del equipo</StyledTableCell>
                            <StyledTableCell align='left'>
                              <Tooltip title='Ver certificado'>
                                <Button variant='contained' size='small' startIcon={<FindInPageIcon />} onClick={() => { setData({ ...data, openPdf: true }) }} />
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              <Badge
                                badgeContent={
                            ctx.tradein.certificate_possession === 'VALID'
                              ? 'Válido'
                              : ctx.tradein.certificate_possession === 'INVALID'
                                ? 'Inválido'
                                : 'Sin validar'
                        }
                                sx={{
                                  '& .MuiBadge-badge': {
                                    backgroundColor:
                              ctx.tradein.certificate_possession === 'VALID'
                                ? '#2e7d32'
                                : ctx.tradein.certificate_possession === 'INVALID'
                                  ? '#f44336'
                                  : '#ff9800',
                                    color: '#fff',
                                    position: 'static',
                                    transform: 'none'
                                  }
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {ctx.tradein.certificate_possession === null
                                ? (
                                  <Box display='flex' justifyContent='flex-end' gap={1}>
                                    <Tooltip title='Marcar como Inválido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='warning'
                                        startIcon={<WarningIcon />}
                                        onClick={() => handleOpen('INVALID', 'possession')}
                                        disabled={ctx.tradein.certificate_theft === 'INVALID' || ctx.tradein.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                    <Tooltip title='Marcar como Válido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='success'
                                        endIcon={<DoneIcon />}
                                        onClick={() => handleOpen('VALID', 'possession')}
                                        disabled={ctx.tradein.certificate_theft === 'INVALID' || ctx.tradein.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                  </Box>
                                  )
                                : (
                                    ''
                                  )}
                            </StyledTableCell>
                          </StyledTableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    {ctx.tradein.certificate_theft === 'INVALID'
                      ? (
                        <NUIAlert type='error'>
                          Lo sentimos, no es posible realizar el Tradein.
                        </NUIAlert>
                        )
                      : ctx.tradein.certificate_theft === null
                        ? (
                          <NUIAlert type='info'>Debe esperar la evaluación del dispositivo para obtener el resultado.</NUIAlert>
                          )
                        : (
                          <NUIAlert type='success'>¡El dispositivo no tiene reportes de robo!.</NUIAlert>
                          )}
                  </Box>
                </>
                )
              : (
                <NUIAlert type='info'>
                  Se requiere el resultado de la evaluación técnica para obtener el certificado.
                </NUIAlert>
                )
          )}
      <Dialog open={ctx.openModal} onClose={handleClose}>
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas marcar el certificado como {ctx.actionType === 'VALID' ? 'válido' : 'inválido'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirmCertificate} color='primary'>Aceptar</Button>
        </DialogActions>
      </Dialog>
      <Modal open={data.openPdf} onClose={() => { setData({ ...data, openPdf: false }) }}>
        <Box sx={style}>
          <iframe src='/demo.pdf' title='pdf' className='w-100 h-100' />
        </Box>
      </Modal>
    </div>
  )
}

export default Certificate
