import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup, Spinner } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { MdInfo } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useTradeinContext } from '../hooks/useTradeinContext'

const Checklist: React.FC<any> = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const [loading, setLoading] = useState(false)
  const userState = useSelector((store: IAppStore) => store.user)
  const [questionsLength, setQuestionsLength] = useState(0)
  useEffect(() => {
    const filteredQuestions = ctx.questions.filter((e: any) => {
      const type = ctx.tradein.sbu === 'Notebook'
        ? 'COMPUTER'
        : ctx.tradein.sbu === 'Tablet'
          ? 'TABLET'
          : 'SMARTWATCH'
      return e.type.toUpperCase() === type
    })

    if (filteredQuestions.length !== questionsLength) {
      setQuestionsLength(filteredQuestions.length)
    }
  }, [ctx.questions, ctx.tradein.sbu, questionsLength])

  const handleChange = (question: string, answerText: string, score: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAnswer = parseInt(event.target.value)
    const idQuestion = parseInt(event.target.name.split('_')[1])

    const answerIndex = ctx.answers.findIndex(answer => answer.id_question === idQuestion)

    if (answerIndex >= 0) {
      ctx.answers[answerIndex] = { id_question: idQuestion, question, text_answer: answerText, id_answer: selectedAnswer, score }
    } else {
      ctx.answers.push({ id_question: idQuestion, question, text_answer: answerText, id_answer: selectedAnswer, score })
    }

    saveCtx({
      ...ctx,
      answers: ctx.answers
    })
  }

  const handleSave = async () => {
    setLoading(true)
    const objChecklist = {
      stage: 5,
      id_tradein: ctx.tradein.id,
      array_answers: ctx.answers,
      id_user: userState.id
    }
    await webApiService.update(objChecklist)
      .then(() => {
        saveCtx({ ...ctx, reload: true, openModal: false })
        enqueueSnackbar('Checklist ingresado!', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el checklist.', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        console.log('error ==>', error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const getSelectedAnswer = (idQuestion: number) => {
    const answer = ctx.answers.find(answer => answer.id_question === idQuestion)
    return answer && answer.id_answer !== undefined ? answer.id_answer.toString() : ''
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Checklist</h6>
      {loading
        ? <Spinner />
        : (
          <div className='mt-2'>
            {ctx.tradein.serie !== null
              ? ctx.tradein.entry_form_status === 0
                ? (
                  <div>
                    <p className='mb-2'>El formulario permite validar el estado funcional del equipo a nivel de sistema y hardware.</p>
                    <Button className='mb-2' color='primary' onClick={() => saveCtx({ ...ctx, openModal: true })}>
                      Realizar Checklist
                    </Button>
                    <p className='text-sm p-4 bg-blue-100 border border-blue-300 rounded-md text-black mb-4 flex items-center'>
                      <MdInfo className='me-2 text-blue-500 text-xl' />
                      Debe realizar el checklist para generar el contrato
                    </p>
                  </div>
                  )
                : (
                  <div>
                    <Button color='primary' onClick={() => saveCtx({ ...ctx, openModal: true })} className='mb-2'>
                      Respuestas aquí
                    </Button>
                    <p className='text-sm p-4 bg-green-100 border border-green-300 rounded-md text-black mb-4 flex items-center'>
                      Checklist ingresado con éxito.
                    </p>
                  </div>)
              : (
                <p className='text-sm p-4 bg-yellow-100 border border-yellow-300 rounded-md text-black mb-4 flex items-center'>
                  Debe ingresar el numero de serie para realizar el Checklist
                </p>
                )}
          </div>
          )}

      <Modal isOpen={ctx.openModal} onClose={() => saveCtx({ ...ctx, openModal: false })} size='5xl' scrollBehavior='inside'>
        <ModalContent>
          <ModalHeader className='flex flex-col gap-1'>{ctx.tradein.entry_form_status === 0 ? 'Checklist' : 'Respuestas Checklist'}</ModalHeader>
          <ModalBody>
            {
                ctx.tradein.entry_form_status === 0
                  ? (
                    <div className='flex flex-col gap-2'>
                      {ctx.questions && ctx.questions
                        .filter((e: any) => {
                          const type = ctx.tradein.sbu === 'Notebook'
                            ? 'COMPUTER'
                            : ctx.tradein.sbu === 'Tablet'
                              ? 'TABLET'
                              : 'SMARTWATCH'
                          return e.type.toUpperCase() === type
                        })
                        .map((row: any, index: number) => (
                          <div key={index} className='rounded-lg bg-slate-100 text-start p-3'>
                            <RadioGroup
                              label={row.question}
                              name={'question_' + String(row.id_question)}
                              value={getSelectedAnswer(row.id_question)}
                              onChange={(e) => {
                                const selectedAnswer = e.target.value
                                const selectedAnswerText = JSON.parse(row.answers_json).find((answer: any) => answer.id_answer.toString() === selectedAnswer)?.answer || ''
                                const selectedScore = JSON.parse(row.answers_json).find((answer: any) => answer.id_answer.toString() === selectedAnswer)?.score || ''
                                handleChange(row.question, selectedAnswerText, Number(selectedScore), e as React.ChangeEvent<HTMLInputElement>)
                              }}
                            >
                              {JSON.parse(row.answers_json).map((e: any) => (
                                <Radio key={e.id_answer} value={e.id_answer.toString()}>{e.answer}</Radio>
                              ))}
                            </RadioGroup>
                          </div>
                        ))}
                    </div>
                    )
                  : (
                    <div className='grid grid-cols-2 gap-4'>
                      {
                        ctx.entrys.map((entry: any, index: number) => (
                          entry.entrys.map((row: any, rowIndex: number) => {
                            return (
                              <div key={`${index}-${rowIndex}`} className='col-span-2 md:col-span-1'>
                                <div className='rounded-lg bg-slate-100 text-start p-3'>
                                  <p className='mb-2 text-gray-500'>{row.question}</p>
                                  <p className='font-bold'>{row.text_answer}</p>
                                </div>
                              </div>
                            )
                          })
                        ))
                      }
                    </div>
                    )
              }
          </ModalBody>
          <ModalFooter>
            <Button
              size='sm'
              color='danger'
              onClick={() => saveCtx({ ...ctx, openModal: false })}
            >
              Cerrar
            </Button>
            <Button color='primary' size='sm' type='submit' isLoading={loading} isDisabled={ctx.answers.length !== questionsLength} onClick={handleSave} style={{ display: ctx.tradein.entry_form_status === 0 ? 'block' : 'none' }}>
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default Checklist
