import AnimateText from '@/components/AnimateText/AnimateText'
import { webApiService } from '@/services'
import { Button } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StepOne from './components/StepOne'
import StepThree from './components/StepThree'
import StepTwo from './components/StepTwo'
import { useFormularioClienteContext } from './hooks/useFormularioClienteContext'
import { initialStateFormularioClienteContext } from './context/FormularioClienteContext'
import { getMonto } from '@/utils/getMonto'
import { IConfigSeller } from '@/models/configSeller'
import { useSelector } from 'react-redux'
import { terms } from '@/utils/terms'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import { PublicRoutes } from '@/constants/routes'

const FormularioClientePage = () => {
  const { ctx, saveCtx } = useFormularioClienteContext()
  const [data, setData] = useState({ loading: false })
  const { slug } = useParams()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const navigate = useNavigate()
  const handleRestart = async () => {
    console.log('Volviendo a comenzar...')
    window.location.reload()
  }

  const handleNext = async () => {
    if (ctx.steps.current < 2) {
      saveCtx({ ...ctx, steps: { isValid: false, current: ctx.steps.current + 1 } })
    } else {
      setData({ ...data, loading: true })
      const code = ctx.data.sbu.id_sbu === 1 || (ctx.data.sbu.id_sbu === 3 && ctx.data.brand.brand === 'Apple') ? await webApiService.createLoginCode().then(res => res.data) : null

      const obj = {
        id_sbu: ctx.data.sbu.id_sbu,
        sbu: ctx.data.sbu.name,
        brand: ctx.data.brand.brand,
        model: ctx.data.model.model,
        capacity: ctx.data.capacity.capacity,
        cpu: ctx.data.cpu.name,
        cpu_generation: ctx.data.generation.cpu_generation,
        screen: ctx.data.screen.screen,
        ram: ctx.data.ram.ram,
        name: ctx.data.name,
        lastname: ctx.data.lastname,
        email: ctx.data.email,
        // discount_amount: (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || (ctx.data.sbu.id_sbu !== 2 && ctx.data.capacity.id_capacity === 0) ? ctx.data.model.amount - resultado : ctx.data.capacity.amount - resultado,
        gpu: ctx.data.gpu.name,
        discount_amount: getMonto(ctx.data),
        answers: ctx.data.answers,
        login_code: code,
        seller: slug
      }

      console.log('obj =>', obj)

      await webApiService.save(obj).then(res => {
        console.log('🚀 ~ awaitwebApiService.saveData ~ res:', res)

        enqueueSnackbar('Sus datos fueron enviados. Revise su correo por favor...', { variant: 'success' })
        saveCtx(initialStateFormularioClienteContext)

        // solo para reuse
        if (slug === 'reuse') {
          const data = { id: res.data.insertId, discount: obj.discount_amount }
          window.parent.postMessage(data, '*')
        } else {
          navigate(PublicRoutes.SUCCESS.replace(':slug', String(slug)))
        }
      }).catch(err => {
        enqueueSnackbar('No fue posible guardar la información del trade-in: ' + err, { variant: 'error' })
      }).finally(() => {
        setData({ ...data, loading: false })
      })
    }
  }

  const handleBack = () => {
    saveCtx({ ...ctx, steps: { isValid: true, current: ctx.steps.current - 1 } })
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <StepOne />
      case 1:
        return <StepTwo />
      case 2:
        return <StepThree />
      default:
        return 'Redireccionando al agendamiento...'
    }
  }

  return (
    <div className='flex flex-col h-svh min-h-[600px]'>
      <header className='py-4 container  max-w-[900px] mb-4 mx-auto px-4 md:px-2'>
        {ctx.steps.current === 0 ? <AnimateText text='Elige tu equipo actual' className='text-4xl font-bold mb-5' /> : null}
        {ctx.steps.current === 1 ? <AnimateText text='Evalúa tu equipo' className='text-4xl font-bold mb-5' /> : null}
        {ctx.steps.current === 2 ? <AnimateText text='¡Casi listo! Ingresa tu email' className='text-4xl font-bold mb-5' /> : null}
      </header>
      <section className='container mx-auto max-w-[900px] md:px-2'>
        <div className='grid grid-cols-3 gap-x-3 px-2 md:px-0'>
          <div className={ctx.steps.current >= 0 ? 'border-b-4 border-black' : 'border-b-4 border-gray-300'} />
          <div className={ctx.steps.current >= 1 ? 'border-b-4 border-black' : 'border-b-4 border-gray-300'} />
          <div className={ctx.steps.current >= 2 ? 'border-b-4 border-black' : 'border-b-4 border-gray-300'} />
        </div>
        {getStepContent(ctx.steps.current)}
      </section>
      <footer>
        <div className='container mx-auto text-center max-w-[900px]'>
          <div className={ctx.steps.current > 0 ? 'flex justify-between' : 'w-auto inline-block'}>
            <Button
              className={ctx.steps.current === 2 ? 'rounded-md uppercase bg-gray-50 border-1 border-black me-2 hidden' : 'hidden'}
              onClick={handleRestart}
              isDisabled={data.loading}
            >
              Volver a empezar
            </Button>
            <Button startContent={<MdArrowBack />} isDisabled={ctx.steps.current === 0} onClick={handleBack} className={ctx.steps.current !== 0 ? 'rounded-full bg-gray-50 border-1 border-gray-50 py-6 px-10' : 'hidden'}>
              Paso anterior
            </Button>
            <div className={ctx.steps.current < 2 ? 'ms-2' : 'mt-2'}>
              <Button
                fullWidth
                className='rounded-full bg-black text-white disabled:bg-gray-300 disabled:border-gray-300 border-1 py-6 px-10'
                onClick={handleNext}
                size={ctx.steps.current === 2 ? 'lg' : undefined}
                isLoading={data.loading}
                endContent={<MdArrowForward />}
                isDisabled={(ctx.steps.current < 2 && !ctx.steps.isValid) || (ctx.steps.current === 2 && ctx.data.email === '') || (ctx.steps.current === 2 && ctx.data.name === '') || (ctx.data.email !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email))}
              >
                {ctx.steps.current === 2 ? 'Finalizar' : 'Continuar'}
              </Button>
            </div>
          </div>

        </div>
      </footer>
      {configSeller.config.terms !== undefined &&
          configSeller.config.terms !== '' &&
          slug === 'falabella' &&
          terms(configSeller.config.terms, true)}
    </div>
  )
}

export default FormularioClientePage
