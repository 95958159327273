import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { PublicRoutes } from '../constants/routes'
import { IUser } from '../models/user.model'

interface IOwnProps{
  roles: Array<number>
}

const AuthRoleRouterGuard: FC<IOwnProps> = ({ roles }) => {
  const userState = useSelector((store: { user: IUser }) => store.user)
  const { slug } = useParams<{ slug: string }>()
  const PrivateValidationFragment = <Outlet />

  return userState.id > 0 && roles.includes(userState.id_role) ? PrivateValidationFragment : <Navigate replace to={PublicRoutes.LOGIN.replace(':slug', String(slug))} />
}

export default AuthRoleRouterGuard
