import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { Accept, useDropzone } from 'react-dropzone'
import React, { useCallback, useState } from 'react'
import { fromImage } from 'imtool'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { webApiService } from '@/services'
import { enqueueSnackbar } from 'notistack'

interface IOwnProps {
    idTradein: number
}

const UploadProof: React.FC<IOwnProps> = ({ idTradein }) => {
  const [data, setData] = useState<{ loading: boolean, file: any }>({ loading: false, file: null })
  const [open, setOpen] = useState(false)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    if (validFiles.length > 0) {
      setData({
        ...data,
        file: validFiles[0]
      })
    }
  }, [])

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1
  })

  const removeFile = (_file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setData({
      ...data,
      file: null
    })
  }

  const handleClose = () => {
    setOpen(false)
    setData({
      ...data,
      file: null
    })
  }

  const uploadImage = async () => {
    if (data.file.nameFile === undefined) {
      data.file.blob = await fromImage(data.file).then((tool: any) => tool.thumbnail(800, false).toBlob())
      data.file.base64 = await blobToBase64(data.file.blob)
      delete data.file.blob
    }
    console.log('🚀 ~ uploadImage ~ data.file:', data.file)
    setData({ ...data, loading: true })
    console.log(idTradein)

    webApiService.uploadProof({ id_tradein: idTradein, file: data.file }).then(() => {
      enqueueSnackbar('Imagen cargada con exito', { variant: 'success' })
      setOpen(false)
      setData({ ...data, loading: false })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  return (
    <div>
      <Button size='sm' color='secondary' onClick={() => { setOpen(true) }}>Enviar Comprobante</Button>
      <Modal backdrop='blur' isOpen={open} onClose={handleClose}>
        <ModalContent>
          <ModalHeader className='flex flex-col gap-1'>Adjuntar comprobante</ModalHeader>
          <ModalBody>
            <div
              {...getRootProps()} style={{
                border: data.file !== null ? '1px solid #ccc' : '1px dashed #ff8181',
                borderRadius: '5px',
                padding: '20px',
                textAlign: 'center',
                marginTop: '6px',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#cce5ff' : data.file !== null ? '#fff' : '#ffefef',
                color: isDragActive ? '#ffffff' : '#000000'
              }}
            >
              <input {...getInputProps()} />
              {
                isDragActive
                  ? (
                    <p>Arrastra y suelta el comprobante bancario aquí...</p>
                    )
                  : (
                    <div hidden={data.file !== null}>
                      <h4 className='font-extrabold'>Adjuntar imagen comprobante bancario (Requerido)</h4>
                      <p>Arrastra y suelta la imagen aquí, o haz clic para seleccionar archivos</p>
                    </div>
                    )
              }
              <p color='textSecondary' hidden={data.file !== null}>
                (Solo se permiten archivos .png, .jpg y .jpeg)
              </p>
              <div className='grid grid-cols-1'>
                {data.file !== null
                  ? (
                    <div className='max-w-full'>
                      <img src={URL.createObjectURL(data.file)} alt='preview' className='w-full' />
                      <p>{data.file.name}</p>
                      <Button color='danger' size='sm' onClick={(event: any) => removeFile(data.file, event)}>
                        Eliminar
                      </Button>
                    </div>
                    )
                  : null}

              </div>

            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              color='danger'
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button color='primary' type='submit' onClick={uploadImage}>
              {data.loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default UploadProof
