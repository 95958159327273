import JsBarcode from 'jsbarcode'

export const generateBarcodeBase64 = (code: string): string | null => {
  const canvas = document.createElement('canvas') // Creo un elemento canvas temporal

  // Genero el código de barras en el canvas
  JsBarcode(canvas, code, {
    format: 'CODE128',
    lineColor: '#000',
    width: 2,
    height: 50,
    displayValue: true
  })

  // Convierto el canvas a Base64
  const base64String = canvas.toDataURL('image/png')

  return base64String
}
