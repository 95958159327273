import BgPasto from '@/assets/images/bg-grass.jpg'
import bgLogin from '@/assets/images/bg-login.png'
import { PublicRoutes } from '@/constants/routes'
import { IConfigSeller } from '@/models/configSeller'
import { Link, Image } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { LoginForm } from './components/LoginForm'
const LoginPage = () => {
  const configState = useSelector((store: { config: IConfigSeller }) => store.config)

  return (
    <section className='bg-gray-50 dark:bg-gray-900 grid lg:grid-cols-2 gap-4 min-h-screen md:min-h-[600px] lg:h-screen'>
      <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }}>
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0'>
          <Link href={PublicRoutes.LOGIN} className='logo max-w-64 mb-6'>
            <Image
              src={configState.config.logo}
              alt=''
              className='m-5 rounded-none'
            />
          </Link>
          <LoginForm />
        </div>
      </motion.div>
      <div className='overflow-hidden'>
        <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.8 }} className='h-full'>
          <div className='rounded-s-3xl overflow-hidden hidden lg:block h-full bg-cover' style={{ backgroundImage: `url(${BgPasto})` }}>
            <img src={bgLogin} alt='' className='object-cover h-full mx-auto' />
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default LoginPage
