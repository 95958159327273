import {
  CognitoUserPool
} from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: import.meta.env.VITE_COGNITO_POOL_ID || '', // Your user pool id here
  ClientId: import.meta.env.VITE_COGNITO_WEB_CLIENT_ID || ''// Your client id here
}

export const UserPool = new CognitoUserPool(poolData)
