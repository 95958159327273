import { UploadExcelProps } from '@/models/uploadPrices'
import { Button } from '@nextui-org/react'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import SendData from '@/assets/svg/SendData.svg?react'
import { webApiService } from '@/services'
import { enqueueSnackbar } from 'notistack'

const UploadData: FC<UploadExcelProps> = ({ state, setState }) => {
  const { slug } = useParams<{ slug: string }>()
  const data = {
    slug,
    sbu: Number(state.sbuForTable.key),
    products: state.products
  }

  console.log('data =>', data)

  const handleUpload = async () => {
    if (data.sbu === null) {
      enqueueSnackbar('Seleccione el Sbu.', { variant: 'error' })
      return
    }
    if (data.products.length === 0) {
      enqueueSnackbar('Se debe cargar un archivo.', { variant: 'error' })
      return
    }
    try {
      setState({ ...state, isLoading: true })
      await webApiService.createDevice(data)
      enqueueSnackbar('Se subieron los precios correctamente.', { variant: 'success' })
      console.log('response =>', data)
      setState({ ...state, products: [], file: null, isLoading: false })
    } catch (error) {
      console.error('error =>', error)
      enqueueSnackbar(`Ocurrió el error: ${error}.`, { variant: 'error' })
    }
  }
  return (
    <div className='flex gap-4 items-center justify-center'>
      <Button
        color='success'
        endContent={<SendData />}
        onClick={handleUpload}
        className='h-12 px-4 text-lg font-medium'
      >
        Actualizar precios
      </Button>
    </div>
  )
}

export default UploadData
