import { IConfigSeller } from '@/models/configSeller'
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup, Textarea } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { CSSProperties, useEffect, useState } from 'react'
import { MdCancel } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IAppStore } from '../../../../models/store.model'
import { webApiService } from '../../../../services'
import { useTradeinContext } from '../hooks/useTradeinContext'

const CancelTradein = () => {
  const [data, setData] = useState<{ open: boolean, respuesta: string, loading: boolean, otro: string, valor: string, listCR: any[] }>({ open: false, respuesta: '', loading: false, otro: '', valor: '', listCR: [] })
  const { id, slug } = useParams()
  const { ctx, saveCtx } = useTradeinContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  const handleClose = () => {
    setData({ ...data, open: false, respuesta: '', loading: false, otro: '', valor: '' })
  }

  const handleSave = () => {
    setData({ ...data, loading: true })
    webApiService.rechazarTradein({ cancel_reason: (data.respuesta === 'Otro') ? data.otro : data.respuesta, id_tradein: id, id_user: userState.id, client_value: data.valor, seller: slug }).then(() => {
      enqueueSnackbar('Trade-in Rechazado correctamente', { variant: 'success' })
      setData({ ...data, loading: false, respuesta: '', otro: '', valor: '', open: false })
      saveCtx({ ...ctx, reload: true })
    }).catch((error) => {
      enqueueSnackbar('Hubo un error al rechazar', { variant: 'error' })
      setData({ ...data, loading: false })
      console.log('error ==>', error)
    })
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getAllCR().then((res: any) => {
      setData({ ...data, loading: false, listCR: [...res.data, { id: 0, name: 'Otro', type: 'Usuario' }] })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Button
        variant='shadow'
        color='danger'
        size='sm'
        className='ml-2'
        endContent={<MdCancel style={{ fontSize: '12px' }} />}
        onClick={() => { setData({ ...data, open: true }) }}
      >
        Rechazar Tradein
      </Button>

      <Modal backdrop='blur' isOpen={data.open} onClose={handleClose}>
        <ModalContent>
          <ModalHeader className='flex flex-col gap-1'>Rechazar Trade-in</ModalHeader>
          <ModalBody>
            <h4 className='text-lg mb-2 font-black'>¿Cual es el motivo de rechazo?</h4>
            <RadioGroup
              orientation='vertical'
              onChange={(e) => setData({ ...data, respuesta: e.target.value })}
            >
              {
                data.listCR.filter((e: any) => e.type.toUpperCase() === 'USUARIO').map((a:any) => {
                  return (
                    <Radio key={a.id} value={a.name} style={{ '--seller-color': `${configSeller.config.color}` } as CSSProperties} classNames={{ control: 'bg-[var(--seller-color)] border-[var(--seller-color)]', wrapper: 'group-data-[selected=true]:border-[var(--seller-color)]' }}>{a.name}</Radio>
                  )
                })
              }
            </RadioGroup>
            {
              data.respuesta === 'Otro'
                ? <Textarea
                    label='Motivo'
                    placeholder='Ingrese motivo'
                    value={data.otro}
                    rows={4}
                    fullWidth
                    onValueChange={(value) => { setData({ ...data, otro: value }) }}
                  />
                : null
            }
            <h4 className='text-lg mb-2 font-black'>¿Indicar qué monto le gustaria al cliente interesado recibir por tu equipo?</h4>
            <Input
              label='Ingrese monto'
              value={data.valor}
              fullWidth
              onValueChange={(value) => { setData({ ...data, valor: value }) }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color='danger'
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button color='primary' type='submit' isDisabled={data.valor === '' || data.respuesta === '' || data.loading || (data.otro === '' && data.respuesta === 'Otro')} onClick={handleSave}>
              {data.loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default CancelTradein
