import React from 'react'
import { useTradeinContext } from '../hooks/useTradeinContext'

const Client: React.FC<any> = () => {
  const { ctx } = useTradeinContext()

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Cliente</h6>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 md:col-span-4'>
          <p><strong>Nombre:</strong> {ctx.tradein.name + ' ' + (ctx.tradein.lastname !== null ? ctx.tradein.lastname : '')}</p>
        </div>
        <div className='col-span-12 md:col-span-4'>
          <p><strong>Email:</strong> {ctx.tradein.email}</p>
        </div>
        <div className='col-span-12 md:col-span-4'>
          {ctx.tradein.rut !== null ? <p><strong>{String(import.meta.env.VITE_LABEL_RUT).toUpperCase()}: </strong> {ctx.tradein.rut} </p> : null}
        </div>
        <div className='col-span-12 md:col-span-4' hidden={ctx.tradein.store === null}>
          <p><strong>Tienda: </strong> {ctx.tradein.store} </p>
        </div>
        <div className='col-span-12 md:col-span-4' hidden={ctx.tradein.folio_coppel === null}>
          <p><strong>Folio: </strong> {ctx.tradein.folio_coppel} </p>
        </div>
      </div>
    </div>
  )
}

export default Client
