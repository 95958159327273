import { PrivateRoutes } from '@/constants/routes'
import { UserPool } from '@/constants/UserPool'
import { webApiService } from '@/services'
import { useAppDispatch } from '@/store/store'
import { setUser } from '@/store/user'
import { Button, Input } from '@nextui-org/react'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import { FormEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ModalForgotPassword from './ModalForgotPassword/ModalForgotPassword'
import ModalResetPassword from './ModalResetPassword/ModalResetPassword'

export const LoginForm = () => {
  const [data, setData] = useState<{ username: string, password: string, loading: boolean, newPass: string }>({ username: '', password: '', loading: false, newPass: '' })
  const dispatch = useAppDispatch()
  const { slug } = useParams<{ slug: string }>()
  const navigate = useNavigate()
  const [modal, setModal] = useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })
  const [forgotModal, setForgotModal] = useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setData({ ...data, loading: true })

    // if (!modal.visible) {
    //   setData({ ...data, loading: false })
    // } else {
    //   setData({ ...data, loading: true })
    // }

    const user = new CognitoUser({
      Username: data.username,
      Pool: UserPool
    })
    const authDetails = new AuthenticationDetails({
      Username: data.username,
      Password: data.password
    })

    user.authenticateUser(authDetails, {
      onSuccess: (response) => {
        const _poolId = import.meta.env.VITE_COGNITO_POOL_ID ?? ''
        webApiService.getUserProfile(response.getIdToken().payload.email as string, _poolId as string).then((res) => {
          // console.log('getUserProfile RES : ', res)
          if (res.success === true) {
            res.data.token = response.getIdToken().getJwtToken()
            res.data.logged = true
            dispatch(setUser(res.data))
            navigate(PrivateRoutes.HOME.replace(':slug', String(slug)))
          } else {
            enqueueSnackbar('getUserProfile RES ERROR : ' + res.message, { variant: 'error' })
          }
        }).catch((error: any) => {
          console.log('getProfile error:', error)
          enqueueSnackbar('Hubo un error con token vacío, revisar usuario en base de datos ...', { variant: 'error' })
        }).finally(() => {
          setData({ ...data, loading: false })
        })
      },
      onFailure: (err) => {
        setData({ ...data, loading: false })
        // TypeError: callback.newPasswordRequired is not a function at CognitoUser.authenticateUserInternal
        err.message === 'callback.newPasswordRequired is not a function' ? setModal({ ...modal, visible: true, parametros: { oldPass: data.password, currentUser: user, currentUserName: data.username, setNewPass, modalName: 'temporary' } }) : console.log(err)
        if (err.message === 'Incorrect username or password.') {
          enqueueSnackbar('Usuario o contraseña incorrectos, por favor, intente nuevamente', { variant: 'error' })
        } else {
          enqueueSnackbar('authenticateUser onFailure IF : ' + err.message, { variant: 'error' })
        }
        setData({ ...data, loading: false })
      },
      newPasswordRequired (userAttributes, _requiredAttributes) {
        console.log('🚀 ~ newPasswordRequired ~ userAttributes:', userAttributes)
        console.log('🚀 ~ newPasswordRequired ~ _requiredAttributes:', _requiredAttributes)
        setModal({
          ...modal,
          visible: true,
          parametros: {
            oldPass: data.password,
            currentUser: user,
            currentUserName: data.username,
            cnp: (newPass: string, confirmPassword: string) => {
              if (newPass === '' || confirmPassword === '') {
                enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
                return
              }
              if (newPass !== confirmPassword) {
                enqueueSnackbar('Las contraseñas no coinciden, por favor, intente nuevamente', { variant: 'error' })
                return
              }
              delete userAttributes.email_verified
              delete userAttributes.email
              user.completeNewPasswordChallenge(confirmPassword, userAttributes, {
                onSuccess: (_result) => {
                  console.log('🚀 ~ newPasswordRequired ~ _result:', _result)
                  setModal({ ...modal, visible: false, parametros: { isSuccess: 'success' } })
                  enqueueSnackbar('Contraseña guardada exitosamente, por favor, ingrese con su nueva contraseña', { variant: 'success' })
                  setData({ ...data, loading: false, username: '', password: '' })
                  /*
                  setTimeout(() => {
                    window.location.replace('')
                  }, 3000)
                */
                },
                onFailure: (err) => {
                  console.log('ON FAILURE ERROR LOGIN FORM: ', err)
                  setModal({ ...modal, visible: true, parametros: { isSuccess: 'error' } })
                }
              })
            }
          }
        })
        if (modal.visible) {
          setData({ ...data, loading: true })
        } else {
          setData({ ...data, loading: false })
        }
      }
    })
  }

  const setNewPass = (_newPass: string): void => {
    setData({ ...data, newPass: _newPass })
  }

  const forgotPass = (): void => {
    setForgotModal({ ...forgotModal, visible: true })
  }

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='w-full bg-white rounded-lg shadow dark:border md:mt-0 lg:max-w-unit-9xl xl:min-w-unit-7xl xl:p-0 dark:bg-gray-800 dark:border-gray-700 max-w-xl'
    >
      {modal.visible ? <ModalResetPassword modal={modal} setModal={setModal} /> : null}
      {forgotModal.visible ? <ModalForgotPassword modal={forgotModal} setModal={setForgotModal} /> : null}
      <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
        <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>Iniciar sesión en su cuenta</h1>
        <form className='space-y-4 md:space-y-6' onSubmit={handleSubmit}>
          <div>
            <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Email</label>
            <Input type='email' name='email' value={data.username} onValueChange={(value) => { setData({ ...data, username: value }) }} />
          </div>
          <div>
            <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Password</label>
            <Input type='password' name='password' value={data.password} onValueChange={(value) => { setData({ ...data, password: value }) }} />
          </div>
          <div className='flex items-center justify-end'>
            <a onClick={forgotPass} className='text-sm font-medium hover:underline cursor-pointer' color='primary'>¿Has olvidado tu contraseña?</a>
          </div>
          <Button type='submit' isLoading={data.loading} isDisabled={data.password.trim() === '' || data.username.trim() === ''} color='primary' className='w-full font-medium rounded-lg text-sm px-5 py-2.5'>Ingresar</Button>
        </form>
      </div>
    </motion.div>
  )
}
