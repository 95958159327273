import { motion } from 'framer-motion'
import { FC } from 'react'

interface IOwnProps {
    className?: string
    text: string
}

const AnimateText: FC<IOwnProps> = ({ className, text }) => {
  return (
    <div>
      {text.split('').map((el, i) => (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.25,
            delay: i / 25
          }}
          className={className}
          key={i}
        >
          {el}
        </motion.span>
      ))}
    </div>
  )
}

export default AnimateText
