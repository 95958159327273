export const terms = (terms: string, bgColor: boolean) => {
  const termsText = terms.split('\n').map((paragraph, index) => {
    if (paragraph.includes('Link T&C:')) {
      const link = paragraph.split('Link T&C:')[1].trim()
      return (
        <p key={index} className='text-[13px] text-gray-500 mt-2'>
          <a href={link} target='_blank' rel='noopener noreferrer' className='text-blue-500 underline'>T&C aquí</a>.
        </p>
      )
    }

    return (
      <p key={index} className='text-[13px] text-gray-500 mt-2'>
        {paragraph}
      </p>
    )
  })

  return (
    <div className={bgColor ? 'mt-10 bg-[#F9FAFB] py-8 md:px-3' : 'mt-10 py-8 '}>
      <div className='container max-w-[900px] mx-auto px-4'>
        {termsText}
      </div>

    </div>
  )
}
