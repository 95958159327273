import { useContext } from 'react'
import { FormularioClienteContext, FormularioClienteContextType } from '../context/FormularioClienteContext'

export const useFormularioClienteContext = (): FormularioClienteContextType => {
  const context = useContext(FormularioClienteContext)
  if (!context) {
    throw new Error('useFormularioClienteContext must be used within a HomePageProvider')
  }
  return context
}
