import { webApiService } from '@/services'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { Card, CardContent, Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import { Button } from '@nextui-org/react'
import { fromImage } from 'imtool'
import { enqueueSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useTradeinContext } from '../hooks/useTradeinContext'
import { IConfigSeller } from '@/models/configSeller'
import { useSelector } from 'react-redux'
import NUIAlert from '@/components/Alert/Alert'

const Multimedia: React.FC = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const { slug, id } = useParams()
  const [data, setData] = useState<{ loading: boolean, imagenes: any[] }>({ loading: false, imagenes: [] })
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'

  console.log('configSeller.config.s3.FOLDER_APP => ', configSeller.config.s3.FOLDER_APP)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    saveCtx({
      ...ctx,
      files: [...ctx.files, ...validFiles]
    })
  }, [ctx, saveCtx])

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  })

  const removeFile = (file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    saveCtx({
      ...ctx,
      files: ctx.files.filter(f => f !== file)
    })
  }

  const uploadFiles = async () => {
    if (ctx.files.length < 6) {
      enqueueSnackbar('Se deben cargar al menos 6 imágenes', { variant: 'error' })
      return
    }

    for (const element of ctx.files) {
      if (element.nameFile === undefined) {
        console.log('entro', ctx)
        delete element.preview
        element.blob = await fromImage(element).then((tool: any) => tool.thumbnail(800, false).toBlob())
        element.base64 = await blobToBase64(element.blob)
        delete element.blob
      }
    }
    console.log('🚀 ~ forawait ~ ctx.files:', ctx.files)
    setData({ ...data, loading: true })
    webApiService.saveFiles({ id, files: ctx.files, seller: slug, battery: false }).then(() => {
      enqueueSnackbar('Imagenes cargadas con exito', { variant: 'success' })
      loadData()
      saveCtx({ ...ctx, files: [], reload: true })
      setData({ ...data, loading: false })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getFiles(String(id)).then((res: any) => {
      setData({ loading: false, imagenes: res.data.filter((item: any) => !item.name.includes('batteryHealth')) })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // Cleanup URLs
    return () => {
      ctx.files.forEach(file => {
        URL.revokeObjectURL(URL.createObjectURL(file))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.files])

  return (
    <Card>
      <CardContent>
        <div className='container'>
          <div className='grid grid-cols-12 mb-2' style={{ borderBottom: '1px solid #f3f1f1' }}>
            <div className='col-span-9'>
              <Typography variant='h6' component='div' gutterBottom style={{ fontSize: '18px' }}>
                Imágenes del dispositivo
              </Typography>
            </div>
            <div
              className='col-span-3 text-end pb-2' hidden={
              (!isSamsungPeru && (ctx.tradein.isBatteryHealthy === null || ctx.tradein.url_contract === null || ctx.tradein.id_status !== 3)) ||
              (isSamsungPeru && (ctx.tradein.isBatteryHealthy === null || ctx.tradein.id_status !== 4))
              }
            >
              <Button isLoading={data.loading} size='sm' color='primary' onClick={() => uploadFiles()}>
                Cargar imágenes
              </Button>
            </div>
          </div>
          <NUIAlert type='warning'>Se deben cargar al menos 6 fotos. 4  que acrediten la declaración de estado estéticos del dispositivo y 2 del carnet de identidad del cliente por ambos lados.</NUIAlert>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <ImageList sx={data.imagenes.length === 0 ? {} : { width: 500, height: 450 }} cols={3} rowHeight={164}>
                {data.imagenes.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/${configSeller.config.s3.FOLDER_APP}/imagenes/${id}/${item.name} 2x`}
                      src={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/${configSeller.config.s3.FOLDER_APP}/imagenes/${id}/${item.name}`}
                      alt={item.name}
                      loading='lazy'
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <div className={data.imagenes.length > 0 ? 'col-12 col-md-6' : 'col-12'}>
              {(isSamsungPeru && ctx.tradein.isBatteryHealthy !== null && ctx.tradein.id_status === 4) || ctx.tradein.id_status === 3 || (ctx.tradein.id_status === 4 && ctx.displayContract === 0 && ctx.tradein.isBatteryHealthy !== null)
                ? (
                  <div
                    {...getRootProps()} style={{
                      border: '2px dashed #cccccc',
                      borderRadius: '5px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                      color: isDragActive ? '#ffffff' : '#000000'
                    }}
                  >
                    <input {...getInputProps()} />
                    {
                isDragActive
                  ? (
                    <Typography>Arrastra y suelta las imágenes aquí...</Typography>
                    )
                  : (
                    <Typography>Arrastra y suelta las imágenes aquí, o haz clic para seleccionar archivos</Typography>
                    )
              }
                    <Typography variant='body2' color='textSecondary'>
                      (Solo se permiten archivos .png, .jpg y .jpeg)
                    </Typography>
                    <Grid container spacing={2} mt={2}>
                      {ctx.files.map((file, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                          <Card>
                            <CardContent>
                              <img src={URL.createObjectURL(file)} alt={`preview ${index}`} style={{ width: '100%' }} />
                              <Typography variant='body2'>{file.name}</Typography>
                              <Button color='secondary' size='sm' onClick={(event: any) => removeFile(file, event)}>
                                Eliminar
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  )
                : ctx.tradein.hasFiles === 0 && ctx.displayContract === 1
                  ? (
                    <NUIAlert type='info'>Se requiere la firma del contrato para cargar imágenes.</NUIAlert>
                    )
                  : null}
            </div>
          </div>
        </div>

      </CardContent>
      {/* <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' color='success' size='small'>
          Guardar imágenes
        </Button>
      </CardActions> */}
    </Card>
  )
}

export default Multimedia
